import { Box, Button, Typography } from "@mui/material";
import React from "react";

const DetailsButtons = ({
  status,
  requestDetails,
  handleChangeStatusClick,
  handleApproveReject,
  setShowLogs,
}: any) => {
  return (
    <>
      {status === "pending" || status === "inreview" ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 3,
            gap: 20,
          }}
        >
          <>
            <Button
              variant="contained"
              sx={{
                width: "150px",
                borderRadius: "12px",
                bgcolor: "#00D23B",
                "&:hover": { bgcolor: "#00D23B" },
              }}
              onClick={() => {
                handleApproveReject("approve");
              }}
            >
              Approve
            </Button>
            <Button
              variant="contained"
              sx={{
                width: "150px",
                borderRadius: "12px",
                bgcolor: "#F93737",
                "&:hover": { bgcolor: "#F93737" },
              }}
              onClick={() => {
                handleApproveReject("reject");
              }}
            >
              Deny
            </Button>
          </>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            mt: 3,
            gap: 2,
          }}
        >
          <>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",

                width: "200px",
                borderRadius: "12px",
                bgcolor: "#1B8368",
                "&:hover": { bgcolor: "#1B8368" },
              }}
              onClick={() => {
                handleChangeStatusClick(
                  `${status === "approved" ? "reject" : "approve"}`
                );
              }}
            >
              Change Status
            </Button>
            <Button
              variant="text"
              sx={{
                color: "#1B8368",
                textDecoration: "underline",
                textTransform: "none",
                fontSize: "12px",
                width: "150px",
                "&:hover": { textDecoration: "underline" },
              }}
              onClick={() => {
                setShowLogs(true);
              }}
            >
              Check Logs
            </Button>
          </>
        </Box>
      )}
    </>
  );
};

export default DetailsButtons;
