import Slider from "react-slick";
import { useEffect, useState } from "react";
// import { useAppSelector, useAppThunkDispatch } from "../../../redux/hooks";
// import { fetchMasjidById } from "../../../redux/actions/MasjidActions/fetchMasjidById";
import bmember from "../../Photos/Newuiphotos/bmember.webp";
import ancmnticon from "../../Photos/Newuiphotos/menu/aancmnticon.svg";
import abticon from "../../Photos/Newuiphotos/menu/abticon.webp";
import evnticon from "../../Photos/Newuiphotos/menu/evnticn.webp";
import salahicon from "../../Photos/Newuiphotos/menu/salahicn.webp";
import servceicon from "../../Photos/Newuiphotos/menu/serviceicn.webp";
import pgrmicon from "../../Photos/Newuiphotos/menu/pgrmicn.webp";
import Donation from "../../photos/Newuiphotos/menuIcons/donation.svg";
import toast from "react-hot-toast";
import proflePlaceholer from "../../Photos/Newuiphotos/homeicon/profile_placeholder.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  CardActionArea,
  Box,
} from "@mui/material";
import { Link, useNavigate, useNavigation, useParams } from "react-router-dom";
import "./Menu.css"; // CSS file for custom styling

import {
  MyProvider,
  MasjidProfile,
  EventsViewCalender,
  EventsMain,
  Toaster,
  Announcement,
  ProgramsMain,
  BoardMember,
  Services,
  ContactForm,
  Donations,
} from "@msa-software-llc/connectmazjid-vanilaportal";

import MasjidTop from "../ProfileComponents/MasjidTop/MasjidTop";
import { useAppThunkDispatch } from "../../Redux/hooks";
import { FetchMasjidById } from "../../Redux/Actions/MasjidActions/FetchMasjidById";
import SalahTiming from "../../Pages/DetailPages/SalahTiming/SalahTiming";
import { useQuery } from "@apollo/client";
import { getMasjidById } from "../../graphql/Menu";
import useMasjidData from "../../SharedHooks/useMasjidData";
import CustomTooltip from "../Shared/Tooltip/CustomTooltip";
import unVerifiedIcon from "../../Photos/Newuiphotos/menuIcons/unverified.svg";
const Menu = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  // const consumerMasjidId = localAdmin.masjids[0];
  // let AdminMasjidState = useAppSelector((state) => state.AdminMasjid);
  useEffect(() => {
    const styleElement = document.createElement("style");

    const loadDynamicStyles = async () => {
      const { default: vanillaPortalStyle } = await import(
        "@msa-software-llc/connectmazjid-vanilaportal/dist/style.css"
      );
      // Append the stylesheet to the document head
      styleElement.textContent = vanillaPortalStyle;
      document.head.appendChild(styleElement);
    };

    loadDynamicStyles();

    return () => {
      const indexOfMyCss = Array.from(document.head.children).indexOf(
        styleElement
      );
    };
  }, []);
  const navigation = useNavigate();

  const consumerMasjidId = sessionStorage.getItem("masjidId");
  const [masjid, setMasjid] = useState<any>(null);
  const dispatch = useAppThunkDispatch();
  const { tab } = useParams();

  const [followers, setFollowers] = useState<number>(0);
  const [myMasjidCount, setMyMasjidCount] = useState<number>(0);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [showTVConnection, setShowTVConnection] = useState(false);
  const [tmConOpener, setTmConOpener] = useState(false);
  const [isAdminMasjidStateLoaded, setIsAdminMasjidStateLoaded] =
    useState(false);

  // Ensure that openFollowersTooltip and openMusaliTooltip are initialized
  const [isVerified, setIsVerified] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  // const { loading, error, data } = useQuery(getMasjidById(), {
  //   variables: {
  //     id: consumerMasjidId,
  //   },
  //   skip: !masjid,
  // });

  // console.log(error);
  const {
    masjidData,
    isLoading: masjidDataLoading,
    error,
    handleRefetch: refetch,
  } = useMasjidData(consumerMasjidId);

  useEffect(() => {
    if (tab) setActiveTab(+tab);
    if (tab === "0") {
      refetch();
    } else {
      handleAllTooltipsClose();
    }
  }, [tab]);

  useEffect(() => {
    console.log(masjidData);
    if (masjidData && Object.keys(masjidData).length > 0 && !error) {
      console.log(masjidData);
      if (masjidData?.isFreezed) {
        setIsLoading(false);
        toast.error(
          "This masjid is frozen, Please Unfreeze to update details!"
        );
        navigation("/freezeMasjids");
      } else {
        setFollowers(masjidData.followers ?? 0);
        setMyMasjidCount(masjidData.subscribers ?? 0);
        setIsVerified(masjidData.isVerified);

        setMasjid(masjidData);
        setIsLoading(false);
      }
    } else if (error) {
      setIsLoading(false);
      toast.error("Unable to fetch Masjid data");
      navigation("/masjids");
    }
  }, [masjidData, error]);

  // useEffect(() => {
  //   if (masjid && Object.keys(masjid).length > 0) {
  //     setFollowers(masjid.followers ?? 0);
  //     setMyMasjidCount(masjid.subscribers ?? 0);
  //     setIsVerified(masjid.isVerified);
  //   }
  // }, [masjid]);

  // useEffect(() => {
  //   if (AdminMasjidState && AdminMasjidState.lastEditor) {
  //     setIsAdminMasjidStateLoaded(true);
  //   }
  // }, [AdminMasjidState]);

  // Menu items
  const menuItems = [
    {},
    {
      label: "About Masjid",
      link: "/feed/1",
      icon: abticon,
      content: <MasjidProfile consumerMasjidId={consumerMasjidId} />,
    },

    {
      label: "Salah Timings",
      link: "/feed/2",
      icon: salahicon,
      content: (
        <SalahTiming
          currentMasjid={masjid}
          consumerMasjidId={consumerMasjidId}
        />
      ),
    },
    {},
    {
      label: "Events",
      link: "/feed/4",
      icon: evnticon,

      content: (
        <EventsMain consumerMasjidId={consumerMasjidId} isMainAdmin={true} />
      ),
    },
    {
      label: "Announcements",
      link: "/feed/5",
      icon: ancmnticon,
      content: <Announcement consumerMasjidId={consumerMasjidId} />,
    },
    {
      // label: "Donations",
      // link: "/feed/6",
      // icon: Donation,
      // content: (
      //   <Donations consumerMasjidId={consumerMasjidId} isMainAdmin={true} />
      // ),
    },
    {
      label: "Services",
      link: "/feed/7",
      icon: servceicon,
      content: <Services consumerMasjidId={consumerMasjidId} />,
    },
    {},
    {
      label: "Programs",
      link: "/feed/9",
      icon: pgrmicon,
      content: <ProgramsMain consumerMasjidId={consumerMasjidId} />,
    },
    {
      label: "Board Member",
      link: "/feed/10",
      icon: bmember,
      content: <BoardMember consumerMasjidId={consumerMasjidId} />,
    },
  ];
  useEffect(() => {
    const styleElement = document.createElement("style");

    const loadDynamicStyles = async () => {
      const { default: vanillaPortalStyle } = await import(
        "@msa-software-llc/connectmazjid-vanilaportal/dist/style.css"
      );
      // Append the stylesheet to the document head
      styleElement.textContent = vanillaPortalStyle;
      document.head.appendChild(styleElement);
    };

    loadDynamicStyles();

    return () => {
      const indexOfMyCss = Array.from(document.head.children).indexOf(
        styleElement
      );
    };
  }, []);

  const [open, setOpen] = useState(false);
  const [openTooltipId, setOpenTooltipId] = useState<string | null>(null);
  const [tooltipTimeouts, setTooltipTimeouts] = useState<
    Record<string, NodeJS.Timeout>
  >({});

  const handleTooltipOpen = (id: string) => {
    // Clear any existing timeout for this tooltip
    if (tooltipTimeouts[id]) {
      return;
    }
    const newTimeouts: Record<string, NodeJS.Timeout> = {};
    Object.values(tooltipTimeouts).forEach(clearTimeout);
    setTooltipTimeouts({});

    // Open the tooltip
    setOpenTooltipId(id);
    setOpen(true);
    console.log("handleTooltipOpen called with id:", id);
    // Set a timeout to close the tooltip after 10 seconds
    const timeout = setTimeout(() => {
      setOpen(false);
      setOpenTooltipId(null);
      setTooltipTimeouts({});
    }, 10000);

    newTimeouts[id] = timeout;
    // Store the timeout in the Map
    setTooltipTimeouts(newTimeouts);
  };

  const handleClickOutside = (e: MouseEvent) => {
    // Close all tooltips
    e.stopPropagation();
    if (Object.keys(tooltipTimeouts).length > 0) {
      Object.values(tooltipTimeouts).forEach(clearTimeout);
      setTooltipTimeouts({});
      setOpen(false);
      setOpenTooltipId(null);
    }
  };
  const handleAllTooltipsClose = () => {
    if (Object.keys(tooltipTimeouts).length > 0) {
      Object.values(tooltipTimeouts).forEach(clearTimeout);
      setTooltipTimeouts({});
      setOpen(false);
      setOpenTooltipId(null);
    }
  };
  return (
    <MyProvider navigation={navigation}>
      <Toaster />
      <MasjidTop consumerMasjidId={consumerMasjidId} />
      <div className="main-menu">
        {activeTab === 0 ? (
          <div className="home_main_container">
            <div className="home_desktop">
              <div className="masjid_header">
                <img
                  src={masjid?.masjidProfilePhoto || proflePlaceholer}
                  alt="Masjid"
                  className="header-image"
                />
                <span className="header-name">
                  {masjid?.masjidName}
                  {!isVerified && (
                    <CustomTooltip
                      id="6"
                      open={openTooltipId === "6" && open}
                      handleTooltipOpen={handleTooltipOpen}
                      text="Verification pending for this masjid."
                      handleClickOutside={handleClickOutside}
                      popperProps={{
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [0, -14], // Adjust the tooltip position
                            },
                          },
                        ],
                      }}
                      icon={
                        <img
                          src={unVerifiedIcon}
                          alt="Info Icon"
                          style={{ marginLeft: "8px", width: "20px" }}
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent triggering the document click event
                            handleTooltipOpen("6");
                          }}
                        />
                      }
                    />
                  )}
                </span>
                <span className="header-followers">
                  <b>{followers}</b>
                  <b>
                    Follower{" "}
                    <CustomTooltip
                      id="1"
                      open={openTooltipId === "1" && open}
                      handleTooltipOpen={handleTooltipOpen}
                      text="Users who have subscribed to receive updates from your Masjid, including events, programs, etc."
                      handleClickOutside={handleClickOutside}
                    />
                  </b>
                </span>
                <span className="header-myMasjid">
                  <b>{myMasjidCount}</b>{" "}
                  <b>
                    Musali
                    <CustomTooltip
                      id="2"
                      open={openTooltipId === "2" && open}
                      handleTooltipOpen={handleTooltipOpen}
                      text="Users who have set Salah timings based on your Masjid."
                      handleClickOutside={handleClickOutside}
                    />
                  </b>
                </span>
                {/* <span className="header-followers">{followers} Follower</span>
              <span className="header-myMasjid">{myMasjidCount} MyMasjid</span> */}
              </div>
            </div>
            <div className="slider-container">
              <div className="slider-container-1">
                <Slider {...settings}>
                  {masjid?.masjidPhotos?.length > 0 ? (
                    masjid?.masjidPhotos?.map((photo: any, index: number) => (
                      <div key={index} className="slide">
                        <img
                          src={photo.url}
                          alt={`Masjid Slide ${index}`}
                          className="slide-image"
                        />
                      </div>
                    ))
                  ) : (
                    <div className="slide">
                      <img
                        src={proflePlaceholer}
                        alt={`Masjid Slide}`}
                        className="slide-image"
                      />
                    </div>
                  )}
                </Slider>
              </div>

              <div className="profile-section">
                {/* <div className="followers">
                <div className="count">{followers}</div>
                <div className="label">Followers</div>
              </div> */}
                <div className="profile-image-container">
                  <img
                    src={masjid?.masjidProfilePhoto || proflePlaceholer}
                    alt="Profile"
                    className="profile-image"
                  />
                </div>
                {/* <div className="my-masjid">
                <div className="count">{myMasjidCount}</div>
                <div className="label">My Masjid</div>
              </div> */}
              </div>
              <div className="menu-container">
                <b className="header-name-1">{masjid?.masjidName}</b>

                <Grid container spacing={0} style={{ padding: 0 }}>
                  {menuItems.map((item, index) => {
                    if (
                      index === 0 ||
                      index === 3 ||
                      index === 6 ||
                      index === 8
                      // index === 9
                    ) {
                      return null;
                    }
                    return (
                      <Grid
                        item
                        xs={4}
                        sm={4}
                        md={3}
                        key={index}
                        style={{ padding: "5px" }}
                      >
                        <Card
                          component={Link}
                          to={item.link}
                          onClick={() => setActiveTab(index)}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            height: { xs: "15vh", sm: "16vh", md: "20vh" },
                            borderRadius: "10px",
                            textDecoration: "none",
                          }}
                        >
                          <CardActionArea>
                            <CardContent
                              sx={{ textAlign: "center", padding: 0 }}
                            >
                              <Box
                                component="img"
                                src={item.icon}
                                alt={item.label}
                                sx={{
                                  width: { xs: 25, sm: 30, md: 35 },
                                  height: { xs: 25, sm: 30, md: 35 },
                                }}
                              />
                              <Typography
                                variant="body2"
                                sx={{
                                  fontSize: {
                                    xs: "0.8rem",
                                    sm: "0.8rem",
                                    md: "0.9rem",
                                  },
                                  lineHeight: "1rem",
                                  color: "#1D785A",
                                  fontWeight: "bold",
                                  marginTop: "10px",
                                  textDecoration: "none",
                                }}
                              >
                                {item.label}
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
            </div>
          </div>
        ) : (
          <div className="content-container">
            {menuItems[activeTab].content}
          </div>
        )}
        {/* <TermAndConditions
          tmConOpener={tmConOpener}
          setTmConOpener={setTmConOpener}
        /> */}
        <footer className="terms-footer">
          <Typography
            variant="subtitle2"
            style={{
              textAlign: "center",
              color: "#33443B",
              textDecoration: "underline",
            }}
            onClick={() => setTmConOpener(true)}
          >
            Terms and Conditions
          </Typography>
        </footer>
      </div>
    </MyProvider>
  );
};

export default Menu;
