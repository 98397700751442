import React, { useState, useRef, useEffect } from "react";
import styles from "./Dropdown.module.css";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { Box, CircularProgress } from "@mui/material";
import CancelIcon from "../../../../Photos/Newuiphotos/Admins/CancelVector.png";
import SubmitIcon from "../../../../Photos/Newuiphotos/Admins/SubmitVector.png";
// Updated interface to make `value` optional
interface DropdownOption {
  label: string; // What is shown in the UI
  value?: string; // The actual value to use (optional)
}

interface DropdownProps {
  loadingOptions: boolean;
  label: string; // Label to display for the dropdown
  options: DropdownOption[]; // Array of options with label and (optional) value
  onSelect: (value: string) => void; // Callback to handle option selection
  error?: boolean;
  selectedValue?: string; // Selected value for controlled component behavior
  isMasjidChanged: boolean;
  handleSubmitMasjid: () => void;
  handleCancelMasjid: () => void;
  onInputChange: (query: string) => void;
}

const Dropdown: React.FC<DropdownProps> = ({
  loadingOptions = false,
  label,
  options,
  onSelect,
  onInputChange,
  error,
  selectedValue,
  isMasjidChanged,
  handleSubmitMasjid,
  handleCancelMasjid,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState<string | null>(null); // Display label for selected option
  const [highlightedIndex, setHighlightedIndex] = useState<number | null>(null); // Track the currently highlighted option index
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");

  // Toggle dropdown open/close
  const toggleDropdown = () => {
    // setIsOpen((prev) => !prev);
    setIsOpen(true);
    if (!isOpen) {
      setHighlightedIndex(null); // Reset highlight when opening
      setSearchTerm("");
    } else {
      setSearchTerm(selectedLabel || "");
    }
  };
  // useEffect(() => {
  //   if (!searchTerm) {
  //     setFilteredOptions(options);
  //   } else {
  //     console.log("searchTerm => ", searchTerm);
  //     const newFilteredOptions = options.filter((option) =>
  //       option.label
  //         .trim()
  //         .toLowerCase()
  //         .includes(searchTerm.toLowerCase().trim().toLowerCase())
  //     );
  //     setFilteredOptions(newFilteredOptions);
  //   }
  // }, [options, searchTerm]);

  useEffect(() => {
    if (!searchTerm) {
      onInputChange("");
    } else {
      onInputChange(searchTerm);
    }
  }, [options, searchTerm]);
  // Handle option selection
  const handleOptionClick = (option: DropdownOption) => {
    console.log(option);
    const optionValue =
      option.value === undefined
        ? option.label
        : option.value
        ? option.value
        : ""; // Use value if provided, otherwise use label
    console.log(optionValue);
    console.log(option);
    setSelectedLabel(option.label); // Set the label to be displayed

    setIsOpen(false); // Close dropdown after selection
    onSelect(optionValue); // Pass the value to the parent component
    setHighlightedIndex(null); // Reset highlighted index after selection
  };

  // Handle key navigation: ArrowUp, ArrowDown, Enter
  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "ArrowDown") {
      e.preventDefault();
      setIsOpen(true); // Open dropdown when navigating with arrows
      setHighlightedIndex((prevIndex) =>
        prevIndex === null || prevIndex === options.length - 1
          ? 0
          : prevIndex + 1
      );
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      setIsOpen(true); // Open dropdown when navigating with arrows
      setHighlightedIndex((prevIndex) =>
        prevIndex === null || prevIndex === 0
          ? options.length - 1
          : prevIndex - 1
      );
    } else if (e.key === "Enter" && highlightedIndex !== null) {
      e.preventDefault();
      handleOptionClick(options[highlightedIndex]); // Select highlighted option on Enter
    }
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
        setHighlightedIndex(null); // Reset highlighted index when closing
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Find the selected label based on the selected value, if provided
  useEffect(() => {
    if (selectedValue) {
      const selectedOption = options.find(
        (option) => (option.value || option.label) === selectedValue
      );
      if (selectedOption) {
        setSelectedLabel(selectedOption.label);
      }
    }
  }, [selectedValue, options]);

  return (
    <div
      className={styles.dropdownContainer}
      ref={dropdownRef}
      tabIndex={0} // Make div focusable
      onKeyDown={handleKeyDown} // Listen for keydown events
    >
      <input
        value={isOpen ? searchTerm || "" : selectedLabel || label}
        className={`${styles.dropdownLabel}`}
        style={{ width: "-webkit-fill-available" }}
        // disabled={!isOpen}
        // onClick={toggleDropdown}
        onFocus={toggleDropdown}
        onChange={(e) => {
          console.log("change input");
          setSearchTerm(e.target.value);
        }}
      />
      <div
        style={{
          position: "absolute",
          top: "50%",
          right: "10px",
          transform: "translateY(-50%)",
        }}
      >
        {!isOpen ? (
          <span className={styles.submitcancelicons}>
            <Box
              component="img"
              src={SubmitIcon}
              sx={{ cursor: "pointer" }}
              onClick={(e) => {
                e.stopPropagation();
                handleSubmitMasjid();
              }}
            ></Box>
            <Box
              component="img"
              src={CancelIcon}
              sx={{ cursor: "pointer" }}
              onClick={(e) => {
                e.stopPropagation();
                handleCancelMasjid();
              }}
            ></Box>
          </span>
        ) : isOpen ? (
          <FaChevronUp
            className={styles.dropdownIcon}
            onClick={toggleDropdown}
            style={{ cursor: "pointer" }}
          />
        ) : (
          <FaChevronDown
            className={styles.dropdownIcon}
            onClick={toggleDropdown}
            style={{ cursor: "pointer" }}
          />
        )}
      </div>
      {/* <div
        className={`${styles.dropdownLabel} ${error ? styles.inputError : ""}`}
        onClick={toggleDropdown}
      >
        {selectedLabel || label}
        <input className={`${styles.dropdownLabel}`} />
        {!isOpen ? (
          <span className={styles.submitcancelicons}>
            <Box
              component="img"
              src={SubmitIcon}
              onClick={(e) => {
                e.stopPropagation();
                handleSubmitMasjid();
              }}
            ></Box>
            <Box
              component="img"
              src={CancelIcon}
              onClick={(e) => {
                e.stopPropagation();
                handleCancelMasjid();
              }}
            ></Box>
          </span>
        ) : isOpen ? (
          <FaChevronUp className={styles.dropdownIcon} />
        ) : (
          <FaChevronDown className={styles.dropdownIcon} />
        )}
      </div> */}
      <div
        className={`${styles.dropdown} ${isOpen ? styles.open : styles.close}`}
      >
        {loadingOptions ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            <CircularProgress size={25}></CircularProgress>
          </div>
        ) : options?.length > 0 ? (
          <>
            {selectedValue && (
              <div
                // key={}
                className={`${styles.dropdownOption}`}
                onClick={() =>
                  handleOptionClick({ label: "Select a Masjid", value: "" })
                }
              >
                --Deselect Masjid--
              </div>
            )}
            {options.map((option, index) => (
              <div
                key={index}
                className={`${styles.dropdownOption} ${
                  highlightedIndex === index ? styles.highlighted : ""
                }`}
                onClick={() => handleOptionClick(option)}
              >
                {option.label} {/* Display the label in the dropdown */}
              </div>
            ))}
          </>
        ) : (
          <div className={`${styles.dropdownOption}`}>
            {searchTerm ? "No Matching Masjid Found" : "Start Typing..."}
            {selectedValue && (
              <div
                // key={}
                className={`${styles.dropdownOption}`}
                onClick={() =>
                  handleOptionClick({ label: "Select a Masjid", value: "" })
                }
              >
                --Deselect Masjid--
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Dropdown;
