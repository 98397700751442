import React, { useEffect, useState } from "react";
import StatusActionModal from "../../Shared/StatusActionModal/StatusActionModal";
import { Masjid } from "../../../../Types";
import MasjidDetails from "./MasjidDetails/MasjidDetails";
import { Box, Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { Colors } from "../../../constants/Colors/colors";
import freezeMasjidIcon from "../../../Photos/Newuiphotos/MasjidTable/FreezeMasjid.png";
import { getActualMasjidStatus } from "./Helper";
import { useUpdateMasjidStatus } from "../../../graphql/Masjids/mutation";
import toast from "react-hot-toast";
import { getMasjidById } from "../../../graphql/Menu";
import { useQuery } from "@apollo/client";
import {
  useGetMasjidById,
  useMasjidLedgers,
} from "../../../graphql/Masjids/query";
interface MasjidStatusHandlerProps {
  refetch: () => void;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirm: () => void;
  selectedMasjid: Masjid;
  setSelectedMasjid: React.Dispatch<React.SetStateAction<Masjid | null>>;
}
const MasjidStatusHandler = ({
  refetch,
  open,
  setOpen,
  onConfirm,
  selectedMasjid,
  setSelectedMasjid,
}: MasjidStatusHandlerProps) => {
  const [skipfetchMasjid, setSkipfetchMasjid] = useState(true);
  const [skipfetchLogs, setSkipfetchLogs] = useState(true);
  const {
    loading: loadingmasjidDetails,
    error: masjidError,
    data: masjidDetails,
    refetch: refetchData,
  } = useGetMasjidById(
    selectedMasjid?._id ?? "",
    !selectedMasjid?._id || skipfetchMasjid
  );

  const [showReasonConfirmation, setShowReasonConfirmation] = useState(false);
  const [showLogs, setShowLogs] = useState(false);
  const [changingStatusTo, setChangingStatusTo] = useState("");
  // const [showFinalConfirmation, setShowFinalConfirmation] = useState(false);
  const [reason, setReason] = useState("");
  const [reasonError, setReasonError] = useState(false);

  const {
    loading: loadingLedgers,
    error: ledgerError,
    data: logs,
    refetch: refetchLogs,
  } = useMasjidLedgers(
    selectedMasjid?._id ?? "",
    !selectedMasjid?._id || !showLogs
  );
  const {
    updateStatus,
    loading: statusLoading,
    error: statusError,
    data: statusData,
  } = useUpdateMasjidStatus();

  // useEffect(() => {
  //   console.log("show logs", showLogs);
  //   if (showLogs) {
  //     console.log("show logs");
  //     setSkipfetchLogs(false);
  //   }
  // }, [showLogs]);

  useEffect(() => {
    if (logs && !ledgerError && !loadingLedgers) {
      setSkipfetchLogs(true);
    }
  }, [loadingLedgers, ledgerError, logs]);

  const handleSubmit = async (status: string, reason: string) => {
    if (!reason || !status || !selectedMasjid?._id) return;
    const freeze = status === "approved" ? false : true;
    try {
      await updateStatus({
        masjidId: selectedMasjid?._id ?? "",
        description: reason,
        status,
        freeze,
      });
      toast.success("Successfully Updated Masjid Status");
      setShowReasonConfirmation(false);
      setReason("");
      setReasonError(false);
      setSkipfetchMasjid(false);
      refetch();
      refetchLogs();
    } catch (err) {
      console.error("Error submitting form:", err);
      toast.error("Error Updating Masjid Status");
    }
  };
  useEffect(() => {
    if (masjidDetails && !masjidError && !loadingLedgers) {
      setSkipfetchMasjid(true);
      setSelectedMasjid(masjidDetails);
    } else if (masjidError && !loadingLedgers) {
      setSkipfetchMasjid(true);
    }
  }, [masjidDetails, masjidError, loadingLedgers]);
  return (
    <StatusActionModal
      modalTitle={
        showLogs
          ? "Logs"
          : showReasonConfirmation && changingStatusTo
          ? `Reason for ${
              changingStatusTo === "approved" ? "Verification" : "Rejection"
            }`
          : "Masjid Details"
      }
      open={open}
      onClose={() => {
        if (showReasonConfirmation) {
          setShowReasonConfirmation(false);
        } else if (showLogs) {
          setShowLogs(false);
        } else {
          console.log("close");
          // setSelectedMasjid(null);
          setOpen(false);
        }
      }}
      showReasonConfirmation={showReasonConfirmation}
      setShowReasonConfirmation={setShowReasonConfirmation}
      showLogs={showLogs}
      setShowLogs={setShowLogs}
    >
      <MasjidDetails
        selectedMasjid={selectedMasjid as Masjid}
        logs={logs}
        isLoading={loadingLedgers || loadingmasjidDetails || statusLoading}
        showReasonConfirmation={showReasonConfirmation}
        setShowReasonConfirmation={setShowReasonConfirmation}
        showLogs={showLogs}
        setShowLogs={setShowLogs}
        changingStatusTo={changingStatusTo}
        setChangingStatusTo={setChangingStatusTo}
        reasonError={reasonError}
        setReasonError={setReasonError}
        reason={reason}
        setReason={setReason}
        handleSubmit={handleSubmit}
      />
    </StatusActionModal>
  );
};

export default MasjidStatusHandler;
