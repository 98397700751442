// common enums
export enum AdminRole {
  MUSALI_ADMIN = "musaliadmin",
  SUB_ADMIN = "subadmin", // MASJID ADMIN
  SUPER_ADMIN = "superadmin",
  ADMIN = "admin", //DATA ENTRY
}

export enum MasjidStatus {
  REJECTED = "rejected",
  VERIFIED = "approved",
  UNVERIFIED = "pending",
}

export enum MasjidStatusDisplay {
  REJECTED = "Freezed",
  VERIFIED = "Verified",
  UNVERIFIED = "Unverified",
}
