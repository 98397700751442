import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import customScrollarStyles from "../../Shared/CustomScrollBar.module.css";
import {
  dummyInternalData,
  useAdminLedgers,
  useGetAdminById,
} from "../../../graphql/Admins/query";
import {
  AdminStatus,
  getAdminStatus,
  getFormattedAdminStatus,
} from "../helpers";
import { roleRenamer } from "../../../helpers/RoleRenamer";
import moment from "moment";
import StatusLogs from "../../Shared/StatusLogs/StatusLogs";
import EmailFilledIcon from "../../../Photos/Newuiphotos/Admins/EmailFilledIcon.png";
import approvedBtnTick from "../../../Photos/Newuiphotos/VerificationCenter/approvedBtnTick.webp";
import ReasonConfirmation from "./ReasonConfirmation";
import toast from "react-hot-toast";
import EditIcon from "../../../Photos/Newuiphotos/Icons/el_edit.png";
import ChangeIcon from "../../../Photos/Newuiphotos/Admins/exchange.png";
import Dropdown from "./customDropdown/CustomDropdown";
import InternalAdminForm from "./InternalAdminForm";
import { useAppThunkDispatch } from "../../../Redux/hooks";
import { UpdatingAdminById } from "../../../Redux/Actions/AdminActions/UpdatingAdminById";
import { ChangeSnackbar } from "../../../Redux/Actions/SnackbarActions/SnackbarActions";
import { useMutation } from "@apollo/client";
import { UPDATE_USER_STATUS } from "../../../graphql/Admins/mutation";
import { ReSendingReinvitation } from "../../../Redux/Actions/AuthActions/ResendingInvitation";

const InternalAdminStatushandler = ({
  selectedAdmin,
  setSelectedAdmin,
  open,
  setOpen,
  onClose,
  isLoading,
  refetch,
}) => {
  const dispatch = useAppThunkDispatch();
  const [showLogs, setShowLogs] = useState(false);
  const [showReasonConfirmation, setShowReasonConfirmation] = useState(false);
  const [skipfetchLogs, setSkipfetchLogs] = useState(true);
  const [reason, setReason] = useState("");
  const [reasonError, setReasonError] = useState(false);
  const [skipfetchAdmin, setSkipfetchAdmin] = useState(true);
  const [isChangingMasjid, setIsChangingMasjid] = useState(false);
  const [isMasjidChanged, setIsMasjidChanged] = useState(false);
  const [selectedMasjid, setSelectedMasjid] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [sendInvitationModal, setSendInvitationModal] = useState(false);
  // const [isChangingRole, setIsChangingRole] = useState(false);
  // const [isRoleChanged, setIsRoleChanged] = useState(false);
  // const [selectedRole, setSelectedRole] = useState("");
  const [FreezeModalOpen, setFreezeModalOpen] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [updateUserStatus, { data, loading, error }] =
    useMutation(UPDATE_USER_STATUS);

  const {
    loading: loadingLedgers,
    error: ledgerError,
    data: logs,
    refetch: refetchLogs,
  } = useAdminLedgers(
    selectedAdmin?._id ?? "",
    !selectedAdmin?._id || !showLogs
  );

  const {
    loading: loadingmasjidDetails,
    error: masjidError,
    data: masjidDetails,
    refetch: refetchData,
  } = useGetAdminById(
    selectedAdmin?._id ?? "",
    !selectedAdmin?._id || skipfetchAdmin
  );
  useEffect(() => {
    let timer;
    if (seconds > 0) {
      timer = setInterval(() => {
        setSeconds((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [seconds]);
  // const handleSubmit = async (status: string) => {
  //   if (!reason || !status || !selectedAdmin?._id) return;
  //   console.log("changed", status);
  //   const freeze = status === "approved" ? false : true;
  //   try {
  //     //   await updateStatus({
  //     //     masjidId: selectedAdmin?._id ?? "",
  //     //     description: reason,
  //     //     status,
  //     //     freeze,
  //     //   });
  //     toast.success("Successfully Updated Admin Status");
  //     setShowReasonConfirmation(false);
  //     setReason("");
  //     setReasonError(false);
  //     setSkipfetchAdmin(false);
  //     refetch();
  //     refetchLogs();
  //   } catch (err) {
  //     console.error("Error submitting form:", err);
  //     toast.error("Error Updating Admin Status");
  //   }
  // };
  const handleSendInvitation = () => {
    if (selectedAdmin?.email) {
      toast.loading("Sending Invitation...");
      const response = dispatch(ReSendingReinvitation(selectedAdmin.email));
      response.then(function (result: any) {
        if (result.success) {
          toast.dismiss();
          const snackbarSuccessDetails = {
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Sent the email successfully",
          };
          dispatch(ChangeSnackbar(snackbarSuccessDetails));
          setSendInvitationModal(false);
          setSeconds(30);
        } else {
          toast.dismiss();
          const snackbarFailureDetails = {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Failed to send the email",
          };
          dispatch(ChangeSnackbar(snackbarFailureDetails));
          setSendInvitationModal(false);
        }
      });
    }
  };
  const handleConfirmation = async (status) => {
    console.log(status);
    if (!selectedAdmin?._id) {
      toast.error("Admin not found");
      return;
    }
    let toastid = toast.loading("Updating Status...");

    const freeze = status === "rejected";
    try {
      const response = await updateUserStatus({
        variables: {
          input: {
            userId: selectedAdmin._id,
            description: "",
            status: status,
          },
          freeze: freeze,
        },
      });
      toast.dismiss();

      toast.success("Successfully Updated Admin Status");
      setFreezeModalOpen(false);
      setShowReasonConfirmation(false);
      setOpen(false);
      refetch();
      console.log("Mutation response:", response.data);
    } catch (err) {
      toast.dismiss();

      toast.error("Error Updating Admin Status");
      console.error("Error updating user status:", err);
    }
  };

  // const freezeAdminHandler = () => {

  // const findAdmin = dummyInternalData.find(
  //   (item) => item?._id === selectedAdmin?._id
  // );
  // if (findAdmin) {
  //   let toastid = toast.loading("Updating Status...");
  //   findAdmin.isFreezed = true;
  // }
  // console.log(findAdmin);
  // setTimeout(() => {
  //   setFreezeModalOpen(false);
  //   toast.dismiss();
  //   toast.success("Admin freezed Successfully");
  //   setSelectedAdmin({ ...findAdmin, isFreezed: true });
  // }, 1000);
  // };

  useEffect(() => {
    if (logs && !ledgerError && !loadingLedgers) {
      setSkipfetchLogs(true);
    }
  }, [loadingLedgers, ledgerError, logs]);

  const adminStatus = getAdminStatus(
    selectedAdmin?.isFreezed,
    selectedAdmin?.isVerified
  );
  const ValueFieldStyles = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "#1B8368",
    fontWeight: "bold",
  };
  const getValueFieldStyles = (value: any) => {
    if (value) {
      return ValueFieldStyles;
    } else {
      return { ...ValueFieldStyles, color: "#FF4949" };
    }
  };

  const handleUpdateAdmin = (name, email, role, selectedMasjid) => {
    let updatedData = {
      name: name,
      email: email,
      role: role,
    };
    const response = dispatch(
      UpdatingAdminById(updatedData, selectedAdmin?._id ?? "")
    );
    response.then(function (result: any) {
      if (result.success) {
        setSelectedAdmin(result.data);
        setIsEditing(false);
        refetch();
      } else {
        const snackbarFailureDetails = {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: result.message
            ? "Failed to Load Admin Details : " + result.message
            : "Failed to Load Masjid Details : Internet or Server Issue ",
        };
        dispatch(ChangeSnackbar(snackbarFailureDetails));
      }
    });
    // let toastid = toast.loading("Updating Admin...");
    // console.log(name, email, role, selectedMasjid);
    // // const id =
    // //   dummyInternalData.length > 0
    // //     ? parseInt(dummyInternalData[dummyInternalData.length - 1]._id, 10) + 1
    // //     : 1;
    // const id = selectedAdmin._id;
    // const existingAdmin = dummyInternalData.find((admin) => admin._id === id);
    // if (existingAdmin) {
    //   existingAdmin.name = name;
    //   existingAdmin.email = email;
    //   existingAdmin.role = role;
    //   existingAdmin.masjid = selectedMasjid;
    //   setTimeout(() => {
    //     toast.dismiss();
    //     setIsEditing(false);
    //     setSelectedAdmin(existingAdmin);
    //     // setModalOpen(true);
    //     setShowSendInvitation(true);
    //   }, 1000);
    // } else {
    //   toast.error("Admin not found");
    // }
  };
  return (
    <>
      <Dialog
        open={FreezeModalOpen || sendInvitationModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {FreezeModalOpen
            ? `Are you sure you want to freeze the Admin ?`
            : "Are you sure you want to send an invitation again ?"}
        </DialogTitle>
        <DialogActions style={{ justifyContent: "space-around" }}>
          <Button
            onClick={() => {
              if (FreezeModalOpen) {
                setFreezeModalOpen(false);
              } else {
                setSendInvitationModal(false);
              }
            }}
            variant="outlined"
            // disabled={isFreezing}
          >
            No
          </Button>
          <Button
            onClick={() => {
              if (FreezeModalOpen) {
                handleConfirmation("rejected");
              } else {
                handleSendInvitation();
              }
            }}
            // disabled={isFreezing}
            variant="outlined"
          >
            {
              // isFreezing ? <CircularProgress size="15px" /> :
              <span> Yes </span>
            }
          </Button>
        </DialogActions>
      </Dialog>

      <InternalAdminForm
        open={isEditing}
        setOpen={setIsEditing}
        onClose={() => {
          setIsEditing(false);
        }}
        handleSubmit={handleUpdateAdmin}
        existingDetails={{
          name: selectedAdmin?.name ?? "",
          email: selectedAdmin?.email ?? "",
          role: selectedAdmin?.role ?? "",
          masjid: selectedAdmin?.masjid ?? "",
        }}
        isEditMode={true}
        adminType="internal"
      />
      <Dialog
        open={open}
        onClose={() => {
          if (showReasonConfirmation) {
            setShowReasonConfirmation(false);
            setReason("");
            setReasonError(false);
          } else if (showLogs) {
            setShowLogs(false);
          } else {
            setOpen(false);
            setSelectedMasjid(selectedAdmin.masjid);
            setIsChangingMasjid(false);
            setIsMasjidChanged(false);
            // setSelectedRole(selectedAdmin.role);
            // setIsChangingRole(false);
            // setIsRoleChanged(false);
          }
        }}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: "22px",
          },
        }}
      >
        <DialogTitle
          sx={{
            textAlign: "center",
            borderBottom: "1px solid #ccc",
            color: "#3D5347",
            fontSize: "16px",
            fontWeight: "600",
          }}
        >
          {showLogs
            ? "Logs"
            : showReasonConfirmation
            ? "Reason"
            : "Admin Details"}
          <IconButton
            aria-label="close"
            onClick={() => {
              setOpen(false);
              if (showReasonConfirmation) {
                setShowReasonConfirmation(false);
                setReason("");
                setReasonError(false);
              } else if (showLogs) {
                setShowLogs(false);
              } else {
                setSelectedMasjid("");
                setIsChangingMasjid(false);
                setIsMasjidChanged(false);
                // setSelectedRole("");
                // setIsChangingRole(false);
                // setIsRoleChanged(false);
                onClose();
              }
              // showReasonConfirmation
              //   ? ;
              //   : showLogs ? setShowLogs(false) : onClose();
            }}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          className={customScrollarStyles["custom-scrollbar"]}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: isLoading ? "center" : "flex-start",
            overflowX: "hidden",
            minHeight: showLogs && logs?.length > 0 ? "auto" : "400px",
          }}
        >
          {isLoading ? (
            <CircularProgress />
          ) : showReasonConfirmation ? (
            <ReasonConfirmation
              onConfirm={(status: string) => {
                if (!reason) {
                  setReasonError(true);
                  return;
                }
                handleConfirmation(status);
              }}
              reason={reason}
              setReason={setReason}
              reasonError={reasonError}
              setReasonError={setReasonError}
              isLoading={isLoading}
            />
          ) : showLogs ? (
            <StatusLogs logs={logs} />
          ) : (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                marginTop: "12px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                flex: "auto",
              }}
            >
              <Box>
                <>
                  {getAdminStatus(
                    selectedAdmin?.isFreezed,
                    selectedAdmin?.isVerified
                  ) === "rejected" && (
                    <>
                      <Typography variant="subtitle2" sx={{ mt: 1 }}>
                        Status
                      </Typography>

                      <Typography variant="body2" sx={getValueFieldStyles("")}>
                        Freezed
                      </Typography>
                    </>
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography variant="subtitle2" sx={{ mt: 1 }}>
                        Role
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={getValueFieldStyles(selectedAdmin?.role)}
                      >
                        {roleRenamer(selectedAdmin?.role ?? "")}
                      </Typography>
                    </Box>
                    {/* {adminStatus !== AdminStatus.DEACTIVE && (
                      <Box
                        component={"img"}
                        src={EditIcon}
                        sx={{ width: "12.5px", cursor: "pointer" }}
                        onClick={() => {
                          setIsEditing(true);
                        }}
                      />
                    )} */}
                  </Box>

                  {/* {isChangingRole && (
                  <Dropdown
                    label={roleRenamer(selectedAdmin?.role) || "Data Entry"}
                    options={[
                      {
                        label: "Data Entry",
                        value: "admin",
                      },
                      {
                        label: "Masjid Admin",
                        value: "subadmin",
                      },
                      {
                        label: "Musali Admin",
                        value: "musaliadmin",
                      },
                    ]}
                    onSelect={handleRoleSelection}
                    error={false}
                    selectedValue={selectedRole || selectedAdmin?.role}
                    isMasjidChanged={isRoleChanged}
                    handleSubmitMasjid={handleSubmitRole}
                    handleCancelMasjid={handleCancelRole}
                  ></Dropdown>
                )} */}

                  <Typography variant="subtitle2" sx={{ mt: 2 }}>
                    Full Name
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={getValueFieldStyles(selectedAdmin?.name)}
                  >
                    {selectedAdmin?.name ?? ""}
                  </Typography>
                  <Typography variant="subtitle2" sx={{ mt: 2 }}>
                    Email Address
                  </Typography>

                  <Typography
                    variant="body2"
                    sx={getValueFieldStyles(selectedAdmin?.email)}
                  >
                    {selectedAdmin?.email ?? ""}
                  </Typography>
                </>

                {/* {(adminStatus === AdminStatus.ACTIVE ||
                  adminStatus === AdminStatus.DEACTIVE) && (
                   */}
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box sx={{ width: "50%" }}>
                    <Typography
                      variant="subtitle2"
                      sx={{ mt: 2, textTransform: "capitalize" }}
                    >
                      Created At
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={getValueFieldStyles(selectedAdmin?.createdAt)}
                    >
                      {selectedAdmin?.createdAt
                        ? moment
                            .utc(selectedAdmin.createdAt)
                            .local()
                            .format("DD MMM, YYYY | hh:mm A")
                        : "Not Available"}
                    </Typography>
                  </Box>

                  <Box sx={{ width: "50%" }}>
                    <Typography
                      variant="subtitle2"
                      sx={{ mt: 2, textTransform: "capitalize" }}
                    >
                      Last Updated At
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={getValueFieldStyles(selectedAdmin?.updatedAt)}
                    >
                      {selectedAdmin?.updatedAt
                        ? moment
                            .utc(selectedAdmin.updatedAt)
                            .local()
                            .format("DD MMM, YYYY | hh:mm A")
                        : "Not Available"}
                    </Typography>
                  </Box>
                </Box>
                {/* )} */}
              </Box>
              {(adminStatus === AdminStatus.ACTIVE ||
                adminStatus === AdminStatus.UNVERIFIED) && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "12px",
                    mt: 3,
                    gap: 2,
                  }}
                >
                  <>
                    <Button
                      variant="contained"
                      sx={{
                        width: "200px",
                        borderRadius: "20px",
                        bgcolor: "#FF2D30",
                        "&:hover": { bgcolor: "#FF2D30" },
                        textTransform: "none",
                      }}
                      onClick={() => {
                        setFreezeModalOpen(true);
                      }}
                    >
                      Freeze Admin
                    </Button>
                    {!selectedAdmin?.isVerified && (
                      <Button
                        variant="contained"
                        sx={{
                          width: "200px",
                          borderRadius: "20px",
                          bgcolor: "#1B8368",
                          "&:hover": { bgcolor: "#1B8368" },
                          textTransform: "none",
                          "&.Mui-disabled": {
                            cursor: "not-allowed !important",
                            color: "#686868",
                          },
                        }}
                        onClick={() => {
                          setSendInvitationModal(true);
                          // toast.success("Invitation Sent");
                          // setSeconds(30);
                        }}
                        disabled={seconds > 0}
                      >
                        <Box
                          component={"img"}
                          src={EmailFilledIcon}
                          sx={{ width: "20px", marginRight: "5px" }}
                        />
                        Send Invitation {seconds > 0 && `(${seconds}s)`}
                      </Button>
                    )}
                  </>
                </Box>
              )}
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default InternalAdminStatushandler;
