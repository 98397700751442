import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { Masjid } from "../../../../Types";
import { socialLinksHandler } from "../../../helpers/MasjidExternalLinkHandler";
import { useMutation } from "@apollo/client";
import { UPDATE_MASJID } from "../../../graphql/Masjids/mutation";
import toast from "react-hot-toast";
import EmailChip from "../../../Pages/Shared/EmailChip/EmailChip";

const AddBot = ({
  consumerMasjidId,
  currentMasjid,
  loading: isLoading,
  error,
  fetchMasjid,
}: {
  consumerMasjidId: string;
  currentMasjid: Masjid | undefined;
  loading: boolean;
  error: boolean;
  fetchMasjid: () => void;
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [whatsappGroupName, setWhatsappGroupName] = useState("");
  const [receivedEmail, setReceivedEmail] = useState("");

  const [updateMasjid, { loading }] = useMutation(UPDATE_MASJID);
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    console.log(currentMasjid);
    if (currentMasjid && Object.keys(currentMasjid).length > 0 && !isLoading) {
      setWhatsappGroupName(
        socialLinksHandler("Whatsapp", currentMasjid?.externalLinks) ?? ""
      );
      setReceivedEmail(
        socialLinksHandler("ReceivedEmail", currentMasjid?.externalLinks) ?? ""
      );
    }
  }, [currentMasjid]);

  const handleSubmit = () => {
    if (currentMasjid) {
      const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      if (receivedEmail && !re.test(receivedEmail)) {
        toast.error("Please enter a valid email address.");
        return;
      }
      console.log(currentMasjid);
      const sanitizedLinks = currentMasjid?.externalLinks
        ? currentMasjid.externalLinks.map(({ __typename, ...rest }) => rest)
        : [];

      const filteredLinks = sanitizedLinks.filter(
        (link) => link.name !== "Whatsapp" && link.name !== "ReceivedEmail"
      );

      const updatedLinks = [
        ...filteredLinks,
        ...(whatsappGroupName
          ? [{ name: "Whatsapp", url: whatsappGroupName }]
          : []),
        ...(receivedEmail
          ? [{ name: "ReceivedEmail", url: receivedEmail }]
          : []),
      ];
      const input = {
        masjidName: currentMasjid.masjidName,
        description: currentMasjid.description,
        contact: currentMasjid.contact,
        externalLinks: updatedLinks,
        address: currentMasjid.address,
        location: {
          type: "Point",
          coordinates: currentMasjid?.location?.coordinates ?? [],
        },
      };
      console.log(input);
      updateMasjid({
        variables: {
          id: consumerMasjidId,
          input: input,
        },
      })
        .then((res) => {
          if (res.data) {
            toast.success("Bot Details Updated Successfully");
            fetchMasjid();
            handleCloseModal();
          }
        })
        .catch((err) => {
          toast.error("Error Adding Bot");
          console.log(err);
        });
    } else {
      toast.error("Something went wrong. Masjid Details not found.");
      console.log("no current masjid");
    }
  };
  return (
    <div>
      <Button
        style={{
          border: "1px solid green",
          color: "green",
          height: "20px",
          fontSize: "10px",
          marginLeft: "10px",
          marginBottom: "10px",
          cursor: "Pointer",
        }}
        onClick={() => setOpenModal(true)}
      >
        Add Bot
      </Button>

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            width: "400px",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 8,
            p: 4,
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6" gutterBottom>
              Add Bot
            </Typography>
            <IconButton aria-label="cancel" onClick={handleCloseModal}>
              <CancelIcon />
            </IconButton>
          </Box>

          <Grid container justifyContent="space-between">
            {/* <Typography variant="h6" gutterBottom>
              Whatsapp Group
            </Typography> */}
            <TextField
              margin="dense"
              label="WhatsApp Group Name"
              fullWidth
              value={whatsappGroupName}
              onChange={(e) => setWhatsappGroupName(e.target.value)}
            />
            {/* <Typography variant="h6" gutterBottom>
              Recieved Email
            </Typography> */}
            <TextField
              margin="dense"
              label="Received Email"
              fullWidth
              value={receivedEmail}
              onChange={(e) => setReceivedEmail(e.target.value)}
            />
          </Grid>
          <Button
            variant="contained"
            id="widget-btn"
            onClick={handleSubmit}
            disabled={loading}
            fullWidth
            sx={{ marginTop: "10px" }}
          >
            {loading ? <CircularProgress size={24} /> : "Update Bot Details"}
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default AddBot;
