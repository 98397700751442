import * as api from "../../../APICalls/index";
import jwt_decode from "jwt-decode";
import { Action, AnyAction, Dispatch } from "redux";
import { UserActionType } from "../../../../Types";

type formDataType = {
  email: string;
  password: string;
};

export const authLogin = (
  formData: formDataType,
  CaptchaValue: string
) => async (dispatch: Dispatch<UserActionType>) => {
  try {
    const response = await api.LoginAdmin(formData, CaptchaValue);
    console.log(response.data.data.user);
    if (response.status === 200) {
      if (response.data.data.isTwoFactorAuthentication) {
        let isTwoFAUser = {
          success: true,
          TwoFAUser: true,
          adminId: response.data.data.user._id,
        };

        return isTwoFAUser;
      } else {
        let isTwoFAUser = {
          success: true,
          TwoFAUser: false,
          adminId: response.data.data.user._id,
        };
        //cookies 1
        localStorage.setItem(
          "authTokens",
          JSON.stringify(response.data.data.token)
        );
        // setCookie('authTokens', response.data.data.token, 7);
        dispatch({ type: "AUTH_LOGIN", payload: response.data.data.user });

        window.location.reload();
        return isTwoFAUser;
      }
    }

    return response.data;
  } catch (error) {
    let isTwoFAUser = {
      success: false,
      TwoFAUser: false,
      error: `Failed to Login`,
      adminId: "",
      message: error.response.data.data.error,
    };
    return isTwoFAUser;
  }
};
