// common enums

export enum AdminStatus {
  ACTIVE = "approved",
  DEACTIVE = "rejected",
  UNVERIFIED = "pending",
}

export enum AdminStatusDisplay {
  ACTIVE = "Active",
  DEACTIVE = "Deactive",
  UNVERIFIED = "Pending",
}

export const getFormattedAdminStatus = (adminStatus: string) => {
  if (adminStatus === AdminStatus.ACTIVE || adminStatus === null) {
    return AdminStatusDisplay.ACTIVE;
  } else if (adminStatus === AdminStatus.DEACTIVE) {
    return AdminStatusDisplay.DEACTIVE;
  } else if (adminStatus === AdminStatus.UNVERIFIED) {
    return AdminStatusDisplay.UNVERIFIED;
  } else if (adminStatus === "frozen") {
    return AdminStatusDisplay.DEACTIVE;
  } else {
    return "";
  }
};

export const getAdminStatus = (isFreeze: boolean, isVerified: boolean) => {
  if (isFreeze) {
    return "rejected";
  }

  if (isVerified) {
    return "approved";
  }

  return "pending";
};
