import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import moment from "moment";
// import { getFreezedAdmins } from "../../../Redux/Actions/AdminActions/FetchingFreezedAdmins";
import { ChangeSnackbar } from "../../../Redux/Actions/SnackbarActions/SnackbarActions";
import "./FreezeAdminTable.scss";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { CircularProgress } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { FreezeAdmin } from "../../../Redux/Actions/AdminActions/FreezingAdmin";
import { useAppThunkDispatch } from "../../../Redux/hooks";
import { CellDataType, User } from "../../../../Types";
import { DialogContent, IconButton, Typography } from "@mui/material";
import { roleRenamer } from "../../../helpers/RoleRenamer";
import ReasonConfirmation from "../../AdminComponents/ExternalAdmins/ReasonConfirmation";
import toast from "react-hot-toast";
import CloseIcon from "@mui/icons-material/Close";
import customScrollarStyles from "../../Shared/CustomScrollBar.module.css";
import { useMutation } from "@apollo/client";
import { UPDATE_USER_STATUS } from "../../../graphql/Admins/mutation";
import { getFreezedAdmins } from "../../../graphql/Admins/query";

const FreezeAdminTable = () => {
  const dispatch = useAppThunkDispatch();
  let navigate = useNavigate();
  const [FreezedAdmins, setFreezedAdmins] = useState([]);
  const [isUnFreezing, setisUnFreezing] = useState(false);
  const [FreezeModalOpen, setFreezeModalOpen] = useState(false);
  const [showReasonConfirmation, setShowReasonConfirmation] = useState(false);
  const [UnfreezingAdmin, setUnfreezingAdmin] = useState<User>();
  const [reason, setReason] = useState("");
  const [reasonError, setReasonError] = useState(false);

  const handleModelOpen = (adminDetails: any) => {
    setShowReasonConfirmation(true);
    setUnfreezingAdmin(adminDetails.row);
  };
  const [updateUserStatus, { data, loading, error }] =
    useMutation(UPDATE_USER_STATUS);

  const {
    data: freezedAdminsData,
    loading: loadingfreezedAdmins,
    error: freezedAdminsError,
    refetch: handleFetchAdmins,
  } = getFreezedAdmins(false);

  useEffect(() => {
    console.log();
    if (!loadingfreezedAdmins && !freezedAdminsError) {
      console.log(freezedAdminsData);
      if (freezedAdminsData) {
        setFreezedAdmins(freezedAdminsData);
      }
    } else if (!loadingfreezedAdmins && freezedAdminsError) {
      toast.error("Failed to fetch Freezed Admins");
    }
  }, [freezedAdminsData, loadingfreezedAdmins, freezedAdminsError]);
  // const handleUnfreeze = async (status: string) => {
  //   // if (!reason || !status || !selectedAdmin?._id) return;
  //   // console.log("changed", status);
  //   // const freeze = status === "approved" ? false : true;
  //   try {
  //     //   await updateStatus({
  //     //     masjidId: selectedAdmin?._id ?? "",
  //     //     description: reason,
  //     //     status,
  //     //     freeze,
  //     //   });
  //     console.log(UnfreezingAdmin);
  //     toast.success("Successfully Unfreezed Admin");
  //     setFreezeModalOpen(false);
  //     setReason("");
  //     setReasonError(false);
  //     // setSkipfetchAdmin(false);
  //     // refetch();
  //     // refetchLogs();
  //   } catch (err) {
  //     console.error("Error submitting form:", err);
  //     toast.error("Error Updating Admin Status");
  //   }
  // };
  const handleConfirmation = async (status) => {
    console.log(status);
    if (!UnfreezingAdmin?._id) {
      toast.error("Admin not found");
      return;
    }
    const freeze = status === "rejected";
    try {
      toast.dismiss();
      toast.loading("Unfreezing Admin...");
      const response = await updateUserStatus({
        variables: {
          input: {
            userId: UnfreezingAdmin._id,
            description: reason,
            status: status,
          },
          freeze: freeze,
        },
      });

      toast.success("Successfully Unfreezed Admin");
      handleFetchAdmins();
      setShowReasonConfirmation(false);
      setReason("");
      setReasonError(false);
    } catch (err) {
      toast.error("Error Updating Admin Status");
      console.error("Error updating user status:", err);
    } finally {
      toast.dismiss();
    }
  };
  const adminColumns = [
    {
      field: "name",
      title: "Admin Name",
      headerName: "Admin Name",
      autoComplete: "off",
      width: 380,
    },
    {
      field: "updatedAt",
      title: "Last Updated On",
      headerName: "Last Updated On",
      autoComplete: "off",
      width: 220,
      renderCell: (params: any) => {
        return (
          <div className="dataUpdatedAt">
            {moment(params.row.updatedAt).format("MM/DD/YYYY hh:mm a")}
          </div>
        );
      },
    },
    {
      field: "email",
      title: "Email",
      headerName: "Email",
      width: 300,
      autoComplete: "off",
    },
    {
      field: "isAddedFromAdmin",
      title: "From Main Admin",
      headerName: "isAddedFromAdmin",
      width: 150,
      autoComplete: "off",
      valueGetter: (params: any) => {
        return params.row.isAddedFromAdmin ? "Yes" : "No";
      },
    },

    {
      field: "role",
      headerName: "Role",
      width: 150,
      valueGetter: (params: any) => roleRenamer(params.row.role),
      renderCell: (params: CellDataType) => {
        const role = params.row.role;
        const displayRole = roleRenamer(role);
        return <Typography variant="body1">{displayRole}</Typography>;
      },
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      renderCell: (params: any) => {
        return (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              handleModelOpen(params);
            }}
            style={{
              border: "1px solid green",
              color: "green",
              height: "20px",
              fontSize: "10px",
              cursor: "Pointer",
            }}
          >
            UnFreeze
          </Button>
        );
      },
    },
  ];

  // useEffect(() => {
  //   const response = dispatch(getFreezedAdmins());
  //   response.then(function (result) {
  //     if (result.success) {
  //       setFreezedAdmins(result.masjids);
  //     } else {
  //       const snackbarFailureDetails = {
  //         snackbarOpen: true,
  //         snackbarType: "error",
  //         snackbarMessage: result.message
  //           ? "Failed to Fetch Freezed Admins Details : " + result.message
  //           : "Failed to Fetch Freezed Admins Details : Internet or Server Issue ",
  //       };
  //       dispatch(ChangeSnackbar(snackbarFailureDetails));
  //     }
  //   });
  // }, []);

  const handleUnFreezeModal = () => {
    setFreezeModalOpen(false);
  };

  // const handleFetchAdmins = () => {
  //   const response = dispatch(getFreezedAdmins());
  //   response.then(function (result) {
  //     if (result.success) {
  //       setFreezedAdmins(result.masjids);
  //     } else {
  //       const snackbarFailureDetails = {
  //         snackbarOpen: true,
  //         snackbarType: "error",
  //         snackbarMessage: result.message
  //           ? "Failed to Fetch Freezed Admins Details : " + result.message
  //           : "Failed to Fetch Freezed Admins Details : Internet or Server Issue ",
  //       };
  //       dispatch(ChangeSnackbar(snackbarFailureDetails));
  //     }
  //   });
  // };

  const handleUnFreezeAdmin = () => {
    setisUnFreezing(true);
    let formData = {
      userId: UnfreezingAdmin?._id,
      isFreeze: false,
    };
    const response = dispatch(FreezeAdmin(formData));

    response.then(function (result) {
      if (result.message === "User updated successfully") {
        const snackbarSuccessDetails = {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "UnFreezed The Admin Successfully",
        };
        dispatch(ChangeSnackbar(snackbarSuccessDetails));
        handleFetchAdmins();
        setisUnFreezing(false);
        setFreezeModalOpen(false);
        navigate("/freezeAdmins");
      } else {
        const snackbarFailureDetails = {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Couldn't Unfreeze the Admin  : " + result.message,
        };
        setisUnFreezing(false);
        setFreezeModalOpen(false);
        dispatch(ChangeSnackbar(snackbarFailureDetails));
      }
    });
  };

  return (
    <div className="FreezedMasjidTableContainer">
      <Dialog
        open={showReasonConfirmation}
        onClose={() => {
          setShowReasonConfirmation(false);
          setReason("");
          setReasonError(false);
        }}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: "22px",
          },
        }}
      >
        <DialogTitle
          sx={{
            textAlign: "center",
            borderBottom: "1px solid #ccc",
            color: "#3D5347",
            fontSize: "16px",
            fontWeight: "600",
          }}
        >
          Reason
          <IconButton
            aria-label="close"
            onClick={() => {
              setShowReasonConfirmation(false);
              setReason("");
              setReasonError(false);
            }}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          className={customScrollarStyles["custom-scrollbar"]}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: false ? "center" : "flex-start",
            overflowX: "hidden",
            minHeight: "400px",
          }}
        >
          {false ? (
            <CircularProgress />
          ) : showReasonConfirmation ? (
            <ReasonConfirmation
              onConfirm={(status: string) => {
                if (!reason) {
                  setReasonError(true);
                  return;
                }
                handleConfirmation(status);
              }}
              reason={reason}
              setReason={setReason}
              reasonError={reasonError}
              setReasonError={setReasonError}
              isLoading={false}
              status={"approving"}
            />
          ) : null}
        </DialogContent>
      </Dialog>

      {/* <Dialog
        open={FreezeModalOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Are you sure you want to Unfreeze the Admin ?`}
        </DialogTitle>
        <DialogActions style={{ justifyContent: "space-around" }}>
          <Button
            onClick={handleUnFreezeModal}
            variant="contained"
            color="inherit"
            disabled={isUnFreezing}
          >
            No
          </Button>
          <Button
            onClick={handleUnFreezeAdmin}
            variant="contained"
            color="primary"
          >
            {" "}
            {isUnFreezing ? (
              <CircularProgress
                // color="black"
                size="15px"
              />
            ) : (
              <span> Yes </span>
            )}{" "}
          </Button>
        </DialogActions>
      </Dialog> */}
      <div className="freezed-admin-table">
        <DataGrid
          loading={loadingfreezedAdmins}
          rows={FreezedAdmins?.length > 0 ? FreezedAdmins : []}
          columns={adminColumns}
          pageSize={10}
          getRowId={(row: User) => row?._id}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </div>
    </div>
  );
};

export default FreezeAdminTable;
