export const Colors = {
  primary: "#42b72a",
  secondary: "#ffffff",
  accent: "#7451f8",
  success: "#00e676",
  warning: "#ffb700",
  error: "#ff5252",
  grey: "#545454",
  darkGrey: "#333333",
  lightGrey: "#e5e5e5",
  white: "#ffffff",
  black: "#000000",
  primaryGreen: "#1B8368",
  neonGreen: "#00D23B",
  greenBlack:"#3D5347",
  warningYellow: "#EDA920",
  rejectedRed: "#FF4949",
};
