import { DELETE_MASJID } from "../../ActionType";
import * as api from "../../../APICalls/index";
import { statusHandler } from "../../../helpers/HelperFunction";

export const ReSendingReinvitation = (email: string) => async () => {
  try {
    const res = await api.ResendingInvitationV3(email);
    let { data } = res;
    data = { ...data, ...statusHandler(res.status) };
    if (data.success) {
      return data;
    }
    return data;
  } catch (error) {
    return error.response.data;
  }
};
