import { Box, Button, CircularProgress, TextField } from "@mui/material";
import React from "react";
import approvedBtnTick from "../../../Photos/Newuiphotos/VerificationCenter/approvedBtnTick.webp";
import rejectedBtnCross from "../../../Photos/Newuiphotos/VerificationCenter/rejectedBtnCross.webp";
import { getAction } from "../../PageComponents/MasjidTable/Helper";
interface ReasonConfirmationprops {
  onConfirm: (status: string) => void;
  newStatus: string;
  reason: string;
  setReason: React.Dispatch<React.SetStateAction<string>>;
  reasonError: boolean;
  setReasonError: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
}
const ReasonConfirmation = ({
  onConfirm,
  newStatus,
  reason,
  setReason,
  reasonError,
  setReasonError,
  isLoading,
}: ReasonConfirmationprops) => {
  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Box sx={{ height: "100%" }} mt={2}>
        <TextField
          fullWidth
          label={`Reason for ${getAction(newStatus)}`}
          sx={{
            border: reasonError ? "1px solid red" : "",
          }}
          value={reason}
          onChange={(e) => {
            setReasonError(false);
            setReason(e.target.value);
          }}
          multiline
          rows={12}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: 3,
          gap: 20,
        }}
      >
        <Button
          variant="contained"
          sx={{
            whiteSpace: "nowrap",
            padding: "10px 20px",
            width: "auto",
            borderRadius: "12px",
            bgcolor: newStatus === "approved" ? "#00D23B" : "#F93737",
            "&:hover": {
              bgcolor: newStatus === "approved" ? "#00D23B" : "#F93737",
            },
            "&.Mui-disabled": { bgcolor: "#B0B0B0", color: "white" },
            fontSize: "13px",
            textTransform: "none",
          }}
          onClick={() => {
            !isLoading ? onConfirm(newStatus) : null;
          }}
        >
          <Box
            component="img"
            src={newStatus === "approved" ? approvedBtnTick : rejectedBtnCross}
            width={newStatus === "approved" ? "14px" : "11px"}
            marginRight="5px"
          />
          {newStatus === "approved"
            ? "Confirm Verification"
            : "Confirm Rejection"}
          {isLoading && (
            <CircularProgress sx={{ marginLeft: "10px" }} size={"20px"} />
          )}
        </Button>
      </Box>
    </Box>
  );
};

export default ReasonConfirmation;
