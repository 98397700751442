import React from 'react'
import PageContainer from '../../../Shared/PageContainer/PageContainer'
import ExternalAdminsTable from '../../../../Components/AdminComponents/ExternalAdmins/ExternalAdminsTable'

const ExternalAdmins = () => {
  return (
    <>
      <PageContainer pt={"50px"} title="External Admins">
        <div className="RightFeedContainer">
          <ExternalAdminsTable />
        </div>
      </PageContainer>
    </>
  )
}

export default ExternalAdmins
