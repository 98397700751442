import { useMutation, useQuery } from "@apollo/client";
import gql from "graphql-tag";

export const UPDATE_MASJID_STATUS = gql`
  mutation UpdateMasjidStatus($input: MasjidLedgerInput!, $freeze: Boolean) {
    updateMasjidStatus(input: $input, freeze: $freeze) {
      _id
      masjidId
      description
      status
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_MASJID = gql`
  mutation UpdateMasjid($id: String!, $input: MasjidInput!) {
    updateMasjid(id: $id, input: $input) {
      _id
      masjidName
      masjidProfilePhoto
      description
      address
      location {
        type
        coordinates
      }
      lastEditor
      contact
      externalLinks {
        name
        url
      }
      isFreezed
      widgets
      isAssigned
      timings {
        _id
        masjidId
        date
        lastEditor
        prayerType
        prayerMethod
        timings {
          _id
          namazName
          type
          azaanTime
          jamaatTime
          offset {
            iqamah
            azaan
          }
        }
        createdAt
        updatedAt
      }
      assignedUser {
        _id
        name
      }
      updatedBy {
        name
        isUnofficial
        role
      }
      subscribers
      followers
      metadata {
        method
        juristic
      }
      createdAt
      updatedAt
    }
  }
`;
export const useUpdateMasjidStatus = () => {
  const [updateMasjidStatus, { loading, error, data }] = useMutation(
    UPDATE_MASJID_STATUS
  );

  const updateStatus = async ({
    masjidId,
    description,
    status,
    freeze,
  }: any) => {
    try {
      const response = await updateMasjidStatus({
        variables: {
          input: {
            masjidId,
            description,
            status,
          },
          freeze,
        },
      });
      return response.data.updateMasjidStatus; // You can modify this based on what you need from the response
    } catch (err) {
      console.error("Error updating masjid status:", err);
      throw err;
    }
  };

  return {
    updateStatus,
    loading,
    error,
    data,
  };
};
