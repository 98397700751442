import { Box, Button, Dialog, DialogContent, Typography } from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import approvedBtnTick from "../../../Photos/Newuiphotos/VerificationCenter/approvedBtnTick.webp";
import rejectedBtnCross from "../../../Photos/Newuiphotos/VerificationCenter/rejectedBtnCross.webp";

interface ConfirmChangeModalProps {
  status: string;
  isChangingModalVisbile: boolean;
  setIsChangingModalVisbile: Dispatch<SetStateAction<boolean>>;
  handleShowReasonConfirmation: (show: boolean) => void;
}
const ConfirmChangeModal = ({
  status,
  isChangingModalVisbile,
  setIsChangingModalVisbile,
  handleShowReasonConfirmation,
}: ConfirmChangeModalProps) => {
  return (
    <Dialog
      open={isChangingModalVisbile}
      onClose={() => {
        setIsChangingModalVisbile(false);
      }}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "22px",
        },
      }}
    >
      <DialogContent>
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          padding={4}
        >
          <Typography variant="h6" sx={{ mb: "30px" }}>
            Change Status
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              sx={{
                width: "180px",
                borderRadius: "12px",
                bgcolor: "#00D23B",
                "&:hover": { bgcolor: "#00D23B" },
                "&.Mui-disabled": { bgcolor: "#B0B0B0", color: "white" },
              }}
              disabled={status === "approved" ? true : false}
              onClick={() => {
                handleShowReasonConfirmation(true);
              }}
            >
              <Box
                component="img"
                src={approvedBtnTick}
                width={"16px"}
                marginRight="5px"
              />
              Approve
            </Button>
            <Button
              variant="contained"
              sx={{
                width: "180px",
                borderRadius: "12px",
                bgcolor: "#F93737",
                "&:hover": { bgcolor: "#F93737" },
                "&.Mui-disabled": { bgcolor: "#B0B0B0", color: "white" },
              }}
              disabled={status === "rejected" ? true : false}
              onClick={() => {
                handleShowReasonConfirmation(true);
              }}
            >
              <Box
                component="img"
                src={rejectedBtnCross}
                width={"11px"}
                marginRight="5px"
              />
              Reject
            </Button>
          </div>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmChangeModal;
