import {
  Box,
  Button,
  CircularProgress,
  debounce,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import ReasonConfirmation from "./ReasonConfirmation";
import { AdminStatus, getFormattedAdminStatus } from "../helpers";
import rejectedBtnCross from "../../../Photos/Newuiphotos/VerificationCenter/rejectedBtnCross.webp";
import approvedBtnTick from "../../../Photos/Newuiphotos/VerificationCenter/approvedBtnTick.webp";
import moment from "moment";
import { roleRenamer } from "../../../helpers/RoleRenamer";
import StatusLogs from "../StatusLogs/StatusLogs";
import toast from "react-hot-toast";
import ChangeIcon from "../../../Photos/Newuiphotos/Admins/exchange.png";
import Dropdown from "../InternalAdmins/customDropdown/CustomDropdown";
import { Masjid } from "../../../../Types";
import { useAppThunkDispatch } from "../../../Redux/hooks";
import { DeleteMasjidInAdminById } from "../../../Redux/Actions/AdminActions/DeleteMasjidInAdminById";
import { UpdateMasjidInAdminById } from "../../../Redux/Actions/AdminActions/UpdatingAdminMasjidById";
import SearchDropdown from "../SearchDropdown/SearchDropdown";
import { useSearchMasjids } from "../../../graphql/Masjids/query";

const ValueFieldStyles = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  color: "#1B8368",
  fontWeight: "bold",
};
const getValueFieldStyles = (value: any) => {
  if (value) {
    return ValueFieldStyles;
  } else {
    return { ...ValueFieldStyles, color: "#FF4949" };
  }
};
const ExternalAdminHandler1 = ({
  isLoading,
  showReasonConfirmation,
  handleSubmit,
  adminStatus,
  reason,
  setReason,
  reasonError,
  setReasonError,
  selectedAdmin,
  showLogs,
  setShowLogs,
  logs,
  handleAction,
  newStatus,
  refetch,
  loadingMasjids,
  dataMasjids,
  errorMasjids,
  setOpen,
}) => {
  const dispatch = useAppThunkDispatch();

  const [isChangingMasjid, setIsChangingMasjid] = useState(false);
  const [selectedMasjid, setSelectedMasjid] = useState("");
  const [isMasjidChanged, setIsMasjidChanged] = useState(false);

  const [masjidsList, setMasjidsList] = useState<Masjid[]>([]);
  const [searchQuery, setSearchQuery] = useState("");

  const { masjids, loading, error } = useSearchMasjids(searchQuery);
  useEffect(() => {
    console.log("searching masjids");
    if (!loading && !error) {
      if (masjids?.length > 0) {
        setMasjidsList(masjids);
      } else {
        console.log("masjids lists blank");
        setMasjidsList([]);
      }
      // Update the list with fetched masjids
    } else if (!loading && error) {
      setMasjidsList([]);
    }
  }, [masjids, loading, error]);
  const debouncedSearchMasjids = useCallback(
    debounce((query) => {
      setSearchQuery(query);
    }, 300),
    [searchQuery]
  );

  const handleSearchMasjid = (query: string) => {
    debouncedSearchMasjids(query);
  };
  const handleMasjidSelection = (masjid: string) => {
    console.log(
      !selectedAdmin?.masjidDetails?.[0]?.masjidId,
      selectedMasjid,
      selectedMasjid !== selectedAdmin?.masjidDetails?.[0]?._id
    );
    // console.log(selectedAdmin.masjid);
    setSelectedMasjid(masjid);
    if (
      (!selectedAdmin?.masjidDetails?.[0]?.masjidId && masjid) ||
      selectedMasjid !== selectedAdmin?.masjidDetails?.[0]?._id
    ) {
      setIsMasjidChanged(true);
    } else {
      setIsMasjidChanged(false);
    }
  };

  const handleUpdationAdmin = async (
    addingUserId: string,
    selectedMasjid: string
  ) => {
    let uploadData = {
      user: addingUserId,
      masjidId: selectedMasjid,
    };

    try {
      const result = await dispatch(UpdateMasjidInAdminById(uploadData));
      return result.success;
    } catch (error) {
      handleCancelMasjid();
      console.error("Error updating admin:", error);
      return false;
    }
  };
  const handleSubmitMasjid = async () => {
    console.log(isMasjidChanged, selectedMasjid);
    try {
      if (
        isMasjidChanged &&
        selectedMasjid !== selectedAdmin?.masjidDetails?.[0]?._id
      ) {
        let toastid = toast.loading("Updating Masjid...");
        if (selectedMasjid) {
          // console.log("result.data => ", result.data);
          const result = await handleUpdationAdmin(
            selectedAdmin._id,
            selectedMasjid
          );
          toast.dismiss(toastid);
          if (result) {
            toast.success("Masjid Updated Successfully");
            setOpen(false);
            refetch();
          } else {
            toast.error("Failed to Update Masjid");
          }
        } else if (!selectedMasjid) {
          const isUserDeleted = await dispatch(
            DeleteMasjidInAdminById({ user: selectedAdmin?._id ?? "" })
          );

          if (isUserDeleted.success) {
            toast.dismiss(toastid);
            toast.success("Masjid Updated Successfully");
            setOpen(false);
            refetch();
          } else {
            toast.error("Failed to Update Masjid");
          }
        }
      }
    } catch (err) {
      toast.dismiss();

      console.log("Error Updating Masjid", err);
      toast.error("Error Updating Masjid");
    }

    setIsChangingMasjid(false);
  };
  const handleCancelMasjid = () => {
    setIsMasjidChanged(false);
    setIsChangingMasjid(false);
    console.log(selectedAdmin.masjid);
    setSelectedMasjid(selectedAdmin.masjid);
  };

  return (
    <div
      style={{
        width: "100%",
        ...(isLoading ? { display: "flex", justifyContent: "center" } : {}),
      }}
    >
      {isLoading ? (
        <CircularProgress />
      ) : showReasonConfirmation ? (
        <ReasonConfirmation
          onConfirm={(status: string) => {
            if (!reason) {
              setReasonError(true);
              return;
            }
            handleSubmit(status);
          }}
          reason={reason}
          setReason={setReason}
          reasonError={reasonError}
          setReasonError={setReasonError}
          isLoading={isLoading}
          status={
            adminStatus === AdminStatus.ACTIVE && selectedAdmin?.isApproved
              ? "freezing"
              : "pending"
          }
        />
      ) : showLogs ? (
        <StatusLogs logs={logs} />
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            marginTop: "12px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            flex: "auto",
          }}
        >
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <Typography variant="subtitle2">Status</Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color:
                      adminStatus === AdminStatus.ACTIVE || adminStatus === null
                        ? "#00D23B"
                        : adminStatus === AdminStatus.UNVERIFIED
                        ? "#EDA920"
                        : "#FF4949",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                  }}
                >
                  {getFormattedAdminStatus(adminStatus) ?? ""}
                </Typography>
              </div>
              {((selectedAdmin?.masjidDetails?.length > 0 &&
                selectedAdmin?.isApproved) ||
                (selectedAdmin?.masjidDetails?.length <= 0 &&
                  adminStatus === AdminStatus.ACTIVE)) && (
                //     selectedAdmin?.isApproved ||
                //   selectedAdmin?.masjidDetails?.length < 0 ||
                //   !selectedAdmin?.masjisDetails[0]?.masjidName
                <Button
                  variant="contained"
                  sx={{
                    width: "105px",
                    borderRadius: "8px",
                    bgcolor: "#FF2D30",
                    "&:hover": { bgcolor: "#FF2D30" },
                    textTransform: "none",
                    padding: "3px",
                    boxShadow: "none",
                    fontSize: "0.775rem",
                  }}
                  onClick={() => {
                    handleAction("freezing");
                  }}
                >
                  <Box
                    component="img"
                    src={rejectedBtnCross}
                    width={"11px"}
                    marginRight="5px"
                  />
                  Freeze
                </Button>
              )}
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div style={{ width: "70%" }}>
                <Typography variant="subtitle2" sx={{ mt: 2 }}>
                  Assign Masjid
                </Typography>
                {!isChangingMasjid && (
                  <Typography
                    variant="body2"
                    sx={getValueFieldStyles(
                      Array.isArray(selectedAdmin?.masjidDetails) &&
                        selectedAdmin?.masjidDetails.length > 0
                        ? selectedAdmin?.masjidDetails?.masjidName
                        : ""
                    )}
                  >
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <span
                        style={{
                          color: !selectedAdmin?.masjidDetails[0]?.masjidName
                            ? "#FF4949"
                            : adminStatus === AdminStatus.DEACTIVE
                            ? "#A9A9A9"
                            : !selectedAdmin?.isApproved &&
                              selectedAdmin?.masjidDetails[0]?.masjidName
                            ? "#F7A400"
                            : selectedAdmin?.isApproved &&
                              selectedAdmin?.masjidDetails[0]?.masjidName
                            ? "#1B8368"
                            : "#FF4949",

                          maxWidth: "75%",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                      >
                        {
                          // adminStatus === AdminStatus.DEACTIVE
                          //   ? `Requested for (${selectedAdmin?.masjid})`:

                          !selectedAdmin?.isApproved &&
                          selectedAdmin?.masjidDetails[0]?.masjidName
                            ? `Pending (${selectedAdmin?.masjidDetails[0]?.masjidName})`
                            : selectedAdmin?.masjidDetails[0]?.masjidName ??
                              "Not Available"
                        }
                      </span>
                    </Box>
                  </Typography>
                )}
              </div>
              <div
                style={{
                  // width: "100%",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  // justifyContent: "space-between",
                }}
              >
                {adminStatus !== AdminStatus.DEACTIVE &&
                  selectedAdmin?.masjidDetails?.length > 0 &&
                  !selectedAdmin?.isApproved && (
                    <Button
                      variant="contained"
                      sx={{
                        width: "110px",
                        borderRadius: "8px",
                        bgcolor: "#00D23B",
                        "&:hover": { bgcolor: "#00D23B" },
                        textTransform: "none",
                        padding: "3px",
                        boxShadow: "none",
                        fontSize: "0.775rem",
                      }}
                      onClick={() => {
                        handleAction("pending");
                        //   handleAssignFreezeConfirmation();
                      }}
                    >
                      <Box
                        component="img"
                        src={approvedBtnTick}
                        width={"14px"}
                        marginRight="5px"
                      />
                      Assign
                    </Button>
                  )}
                {adminStatus !== AdminStatus.DEACTIVE && !isChangingMasjid && (
                  <Button
                    variant="contained"
                    sx={{
                      width: "105px",
                      borderRadius: "8px",
                      bgcolor: "#1B8368",
                      "&:hover": { bgcolor: "#1B8368" },
                      textTransform: "none",
                      padding: "2.5px",
                    }}
                    onClick={() => {
                      toast.dismiss();
                      if (loadingMasjids) {
                        // toast.("Loading Masjids...");
                        setTimeout(() => {
                          toast.dismiss();
                        }, 1000);
                        return;
                      }
                      setIsChangingMasjid(true);
                    }}
                  >
                    {loadingMasjids ? "loading..." : "Change"}
                    {loadingMasjids ? (
                      <CircularProgress
                        sx={{
                          color: "white",
                          marginLeft: "5px",
                        }}
                        size={"20px"}
                      ></CircularProgress>
                    ) : (
                      <Box
                        component={"img"}
                        src={ChangeIcon}
                        sx={{ width: "20px", marginLeft: "5px" }}
                      />
                    )}
                    {/* <Box
                              component={"img"}
                              src={ChangeIcon}
                              sx={{ width: "20px", marginLeft: "5px" }}
                            /> */}
                  </Button>
                )}
              </div>
            </Box>
            {isChangingMasjid && (
              <Box mt={2}>
                {" "}
                {isChangingMasjid && masjidsList && (
                  // <SearchDropdownChange
                  //   options={masjidsList || []}
                  //   placeholder="Type To Search Masjid."
                  //   onInputChange={(query) => {
                  //     if (query === "") {
                  //       setSelectedMasjid("");
                  //     }

                  //     handleSearchMasjid(query);

                  //     // handleSearchMasjid?.(query);
                  //   }} // Call the search function
                  //   onSelect={(selectedOption: any) => {
                  //     console.log(selectedOption);
                  //     handleMasjidSelection(selectedOption);
                  //   }}
                  //   error={false}
                  //   selectedValue={selectedMasjid}
                  //   loading={loading}
                  //   clearData={() => {
                  //     setSelectedMasjid("");
                  //   }}
                  //   handleSubmitMasjid={handleSubmitMasjid}
                  //   handleCancelMasjid={handleCancelMasjid}
                  // />

                  <Box mt={2}>
                    <Dropdown
                      loadingOptions={loading}
                      label={
                        Array.isArray(selectedAdmin?.masjidDetails) &&
                        selectedAdmin?.masjidDetails?.length > 0
                          ? selectedAdmin?.masjidDetails[0]?.masjidName
                          : "Select a Masjid"
                      }
                      options={
                        masjidsList?.length > 0
                          ? [
                              ...masjidsList.map((masjid: Masjid) => ({
                                label: masjid.masjidName,
                                value: masjid._id,
                              })),
                            ]
                          : []
                        // : [{ label: "Select a Masjid", value: "" }]
                        // dataMasjids?.getMasjids &&
                        // Array.isArray(dataMasjids.getMasjids) &&
                        // dataMasjids?.getMasjids.length > 0
                        //   ? [
                        //       { label: "Select a Masjid", value: "" },
                        //       ...dataMasjids.getMasjids
                        //         .slice()
                        //         .sort((a, b) =>
                        //           a.masjidName.localeCompare(b.masjidName)
                        //         )
                        //         .map((masjid: Masjid) => ({
                        //           label: masjid.masjidName,
                        //           value: masjid._id,
                        //         })),
                        //     ]
                        //   : [{ label: "Select a Masjid", value: "" }]
                      }
                      onSelect={handleMasjidSelection}
                      error={false}
                      selectedValue={
                        selectedMasjid
                          ? selectedMasjid
                          : Array.isArray(selectedAdmin?.masjidDetails) &&
                            selectedAdmin.masjidDetails.length > 0
                          ? selectedAdmin?.masjidDetails[0]._id
                          : selectedAdmin?.masjidDetails?.length === 0
                          ? ""
                          : ""
                      }
                      onInputChange={(query: string) => {
                        handleSearchMasjid(query);
                      }}
                      isMasjidChanged={isMasjidChanged}
                      handleSubmitMasjid={handleSubmitMasjid}
                      handleCancelMasjid={handleCancelMasjid}
                    ></Dropdown>
                  </Box>
                )}
              </Box>
            )}
            <>
              <Typography variant="subtitle2" sx={{ mt: 1 }}>
                Role
              </Typography>

              <Typography
                variant="body2"
                sx={getValueFieldStyles(selectedAdmin?.role)}
              >
                {roleRenamer(selectedAdmin?.role ?? "")}
              </Typography>

              <Typography variant="subtitle2" sx={{ mt: 2 }}>
                Full Name
              </Typography>
              <Typography
                variant="body2"
                sx={getValueFieldStyles(selectedAdmin?.name)}
              >
                {selectedAdmin?.name ?? ""}
              </Typography>
              <Typography variant="subtitle2" sx={{ mt: 2 }}>
                Email Address
              </Typography>

              <Typography
                variant="body2"
                sx={getValueFieldStyles(selectedAdmin?.email)}
              >
                {selectedAdmin?.email ?? ""}
              </Typography>

              <Typography variant="subtitle2" sx={{ mt: 2 }}>
                Contact Number
              </Typography>
              <Typography
                variant="body2"
                sx={getValueFieldStyles(selectedAdmin?.contact)}
              >
                {selectedAdmin?.contact ?? "Not Available"}
              </Typography>
            </>

            {(adminStatus === AdminStatus.ACTIVE ||
              adminStatus === AdminStatus.DEACTIVE) &&
              logs.length > 0 && (
                <>
                  <Typography
                    variant="subtitle2"
                    sx={{ mt: 2, textTransform: "capitalize" }}
                  >
                    {getFormattedAdminStatus(logs[0]?.status) ?? ""} By
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={getValueFieldStyles(logs[0]?.userDetails.name)}
                  >
                    {logs[0]?.userDetails.name ?? ""}
                  </Typography>
                  <>
                    <Typography
                      variant="subtitle2"
                      sx={{ mt: 2, textTransform: "capitalize" }}
                    >
                      {getFormattedAdminStatus(logs[0]?.status) ?? ""} On
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={getValueFieldStyles(logs[0]?.createdAt)}
                    >
                      {logs[0]?.createdAt
                        ? moment
                            .utc(logs[0].createdAt)
                            .local()
                            .format("DD MMM, YYYY | hh:mm A")
                        : "Not Available"}
                    </Typography>
                  </>
                </>
              )}
          </Box>
          {(adminStatus === AdminStatus.ACTIVE ||
            adminStatus === AdminStatus.DEACTIVE) && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "12px",
                mt: 3,
                gap: 2,
              }}
            >
              <>
                <Button
                  variant="text"
                  sx={{
                    color: "#3D5347",
                    textDecoration: "underline",
                    textTransform: "none",
                    fontSize: "13.5px",
                    width: "150px",
                    fontWeight: "600",
                    "&:hover": { textDecoration: "underline" },
                  }}
                  onClick={() => {
                    setShowLogs(true);
                  }}
                >
                  Check Logs
                </Button>
              </>
            </Box>
          )}
        </Box>
      )}
    </div>
  );
};

export default ExternalAdminHandler1;
