import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Typography,
  TextField,
  DialogTitle,
  IconButton,
  Divider,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./StatusActionModal.css";

import ConfirmChangeModal from "../../VerificationCenterComponents/Modal/ConfirmChangeModal";
import DetailsButtons from "../../VerificationCenterComponents/Modal/DetailsButtons";
import DetailFields from "../../VerificationCenterComponents/Modal/DetailFields";
import approvedBtnTick from "../../../Photos/Newuiphotos/VerificationCenter/approvedBtnTick.webp";
import rejectedBtnCross from "../../../Photos/Newuiphotos/VerificationCenter/rejectedBtnCross.webp";
import backAngleBracketIcon from "../../../Photos/Newuiphotos/VerificationCenter/backAngleBracketIcon.webp";
import toast from "react-hot-toast";
import moment from "moment";
import { Colors } from "../../../constants/Colors/colors";

interface RequestActionModalProps {
  modalTitle: string;
  open: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  showReasonConfirmation: boolean;
  setShowReasonConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
  showLogs: boolean;
  setShowLogs: React.Dispatch<React.SetStateAction<boolean>>;
}
const StatusActionModal = ({
  modalTitle,
  open,
  onClose,
  children,
  showReasonConfirmation,
  setShowReasonConfirmation,
  showLogs,
  setShowLogs,
}: RequestActionModalProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "22px",
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: "center",
          borderBottom: "1px solid #ccc",
          color: Colors.greenBlack,
          fontSize: "16px",
          fontWeight: "600",
        }}
      >
        {modalTitle}
        <IconButton
          aria-label="close"
          onClick={() => {
            showReasonConfirmation
              ? setShowReasonConfirmation(false)
              : showLogs
              ? setShowLogs(false)
              : onClose();
          }}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {children}
    </Dialog>
  );
};

export default StatusActionModal;
