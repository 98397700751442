// import { gql, useMutation } from "@apollo/client";

import gql from "graphql-tag";

// export const useUpdateMasjidStatus = () => {
//   const [updateMasjidStatus, { loading, error, data }] = useMutation(
//     UPDATE_MASJID_STATUS
//   );

//   const updateStatus = async ({
//     masjidId,
//     description,
//     status,
//     freeze,
//   }: any) => {
//     try {
//       const response = await updateMasjidStatus({
//         variables: {
//           input: {
//             masjidId,
//             description,
//             status,
//           },
//           freeze,
//         },
//       });
//       return response.data.updateMasjidStatus; // You can modify this based on what you need from the response
//     } catch (err) {
//       console.error("Error updating masjid status:", err);
//       throw err;
//     }
//   };

//   return {
//     updateStatus,
//     loading,
//     error,
//     data,
//   };
// };

export const UPDATE_USER_STATUS = gql`
  mutation UpdateUserStatus($input: UserLedgerInput!, $freeze: Boolean!) {
    updateUserStatus(input: $input, freeze: $freeze) {
      _id
      userId
      description
      status
      adminId
      createdAt
      updatedAt
    }
  }
`;

export const USER_MASJID_ACTION = gql`
  mutation UserMasjidAction(
    $userId: String!
    $masjidId: String!
    $action: String!
  ) {
    userMasjidAction(userId: $userId, masjidId: $masjidId, action: $action)
  }
`;
