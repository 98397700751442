import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { useState } from "react";
import { useAppThunkDispatch } from "../../Redux/hooks";
import { FreezeMasjidStatus } from "../../Redux/Actions/MasjidActions/FreezingMasjid";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import ReasonConfirmation from "../Shared/ReasonConfirmation/ReasonConfirmation";
import { Colors } from "../../constants/Colors/colors";
import freezeMasjidIcon from "../../Photos/Newuiphotos/MasjidTable/FreezeMasjid.png";
import { set } from "cypress/types/lodash";
import { useUpdateMasjidStatus } from "../../graphql/Masjids/mutation";
const FreezeMasjid = ({ masjidId }: { masjidId: string }) => {
  const [isFreezing, setisFreezing] = useState(false);
  const [FreezeModalOpen, setFreezeModalOpen] = useState(false);
  const [reason, setReason] = useState("");
  const [reasonError, setReasonError] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppThunkDispatch();
  const {
    updateStatus,
    loading: statusLoading,
    error: statusError,
    data: statusData,
  } = useUpdateMasjidStatus();

  const handleSubmit = async (status: string, reason: string) => {
    if (!reason || !status || !masjidId) return;
    console.log(reason, status, masjidId);
    const freeze = status === "approved" ? false : true;
    try {
      await updateStatus({
        masjidId: masjidId ?? "",
        description: reason,
        status,
        freeze,
      });
      toast.success("Successfully Updated Masjid Status");
      setReason("");
      setReasonError(false);
      setFreezeModalOpen(false);
      navigate("/masjids");
    } catch (err) {
      console.error("Error submitting form:", err);
      toast.error("Error Updating Masjid Status");
    }
  };
  const handleFreezeMasjid = () => {
    setisFreezing(true);
    if (masjidId) {
      const response = dispatch(FreezeMasjidStatus(masjidId));

      response.then(function (result: any) {
        if (result.success) {
          toast.success("Freezed The masjid Successfully");
          setisFreezing(false);
          setFreezeModalOpen(false);
          navigate("/freezeMasjids");
        } else {
          setisFreezing(false);
          setFreezeModalOpen(false);
          toast.error(`Couldn't update the Masjid Profile :${result.message}`);
        }
      });
    }
  };
  return (
    <>
      {/* <Dialog
        open={showFinalConfirmation}
        onClose={() => {
          setFreezeModalOpen(true);
          setShowFinalConfirmation(false);
        }}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: "22px",
            padding: "12px",
          },
        }}
      >
        <DialogTitle
          sx={{
            textAlign: "center",
            color: Colors.greenBlack,
            fontSize: "16px",
            fontWeight: "600",
            padding: "10px",
          }}
        >
          <img
            src={freezeMasjidIcon}
            alt="Freeze Masjid"
            style={{
              width: "45px",
            }}
          />
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            overflowX: "hidden",
          }}
        ></DialogContent>
      </Dialog> */}

      <Dialog
        open={FreezeModalOpen}
        onClose={() => {
          setFreezeModalOpen(false);
          setReasonError(false);
          setReason("");
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: "22px",
            padding: "12px",
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            textAlign: "center",
            color: Colors.greenBlack,
            fontSize: "16px",
            fontWeight: "600",
            padding: "10px",
          }}
        >
          {/* {`Are you sure you want to freeze the masjid ?`} */}
          Reason To Freeze
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            overflowX: "hidden",

            minHeight: "400px",
          }}
        >
          <ReasonConfirmation
            onConfirm={(status) => {
              if (!reason) {
                setReasonError(true);
                return;
              }
              handleSubmit(status, reason);
            }}
            newStatus={"rejected"}
            reason={reason}
            setReason={setReason}
            reasonError={reasonError}
            setReasonError={setReasonError}
            isLoading={statusLoading}
          />
        </DialogContent>
      </Dialog>

      <Button
        onClick={(e) => setFreezeModalOpen(true)}
        style={{
          border: "1px solid red",
          color: "red",
          height: "20px",
          fontSize: "10px",
          marginLeft: "10px",
          marginBottom: "10px",
          cursor: "Pointer",
        }}
      >
        Freeze Masjid
      </Button>
    </>
  );
};

export default FreezeMasjid;
