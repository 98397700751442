import React, { useEffect } from "react";
import { Tooltip, IconButton } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

interface CustomTooltipProps {
  id: string;
  text: string; // Tooltip text
  iconStyle?: React.CSSProperties; // Optional icon styling
  tooltipStyle?: any; // Optional tooltip styling
  popperProps?: any; // Optional PopperProps for positioning
  open: boolean; // Whether the tooltip is currently open
  handleTooltipOpen: (id: string) => void; // Function to open the tooltip
  handleClickOutside: (event: MouseEvent) => void;
  icon?: React.ReactNode; // Optional icon component
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({
  id = "",
  text,
  iconStyle = {},
  tooltipStyle = {},
  popperProps = {},
  open,
  handleTooltipOpen,
  handleClickOutside,
  icon,
}) => {
  useEffect(() => {
    // Add event listener for outside clicks
    document.addEventListener("click", handleClickOutside);

    return () => {
      // Cleanup listener on component unmount
      document.removeEventListener("click", handleClickOutside);
    };
  }, [open, handleClickOutside]);

  return (
    <Tooltip
      title={text}
      arrow
      open={open}
      PopperProps={popperProps} // Pass the PopperProps here
      componentsProps={{
        tooltip: {
          sx: {
            padding: "10px",
            backgroundColor: "white",
            color: "#1D785A",
            fontSize: "12px",
            borderRadius: "10px",
            ...tooltipStyle,
          },
        },
        arrow: {
          sx: {
            color: "white",
          },
        },
      }}
      disableInteractive
    >
      {icon ? (
        <span
          onClick={(e) => {
            e.stopPropagation(); // Prevent triggering the document click event
            handleTooltipOpen(id);
          }}
          style={iconStyle}
        >
          {icon}
        </span>
      ) : (
        <InfoIcon
          onClick={(e) => {
            e.stopPropagation(); // Prevent triggering the document click event
            handleTooltipOpen(id);
          }}
          style={{
            color: "#3D5347",
            marginLeft: "5px",
            fontSize: "15px",
            ...iconStyle,
          }}
        />
      )}
    </Tooltip>
  );
};

export default CustomTooltip;
