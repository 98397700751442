import { Dispatch } from "redux";
import * as api from "../../../APICalls/index";
import { ChangeSnackbar } from "../SnackbarActions/SnackbarActions";
import { statusHandler } from "../../../helpers/HelperFunction";

type updatedDataType = {
  name: string;
  email: string;
  role: string;
  description: string;
};
export const AddingNewAdminWithDescription = (
  upadatedData: updatedDataType
) => async (dispatch: Dispatch<any>) => {
  // return {
  //   message: "Request failed with status code 400",
  //   name: "AxiosError",
  //   stack:
  //     "AxiosError: Request failed with status code 400\n    at settle (http://localhost:5173/node_modules/.vite/deps/axios.js?v=8bc3ccec:1189:12)\n    at XMLHttpRequest.onloadend (http://localhost:5173/node_modules/.vite/deps/axios.js?v=8bc3ccec:1417:7)",
  //   config: {
  //     transitional: {
  //       silentJSONParsing: true,
  //       forcedJSONParsing: true,
  //       clarifyTimeoutError: false,
  //     },
  //     adapter: ["xhr", "http"],
  //     transformRequest: [null],
  //     transformResponse: [null],
  //     timeout: 0,
  //     xsrfCookieName: "XSRF-TOKEN",
  //     xsrfHeaderName: "X-XSRF-TOKEN",
  //     maxContentLength: -1,
  //     maxBodyLength: -1,
  //     env: {},
  //     headers: {
  //       Accept: "application/json, text/plain, */*",
  //       "Content-Type": "application/json",
  //       Authorization:
  //         "Bearer eyJhbGciOiJSUzUxMiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NzdkNTY4NThmNzA2MjU4ZmRlYTc4YmQiLCJyb2xlIjoic3VwZXJhZG1pbiIsImVtYWlsIjoibWlyemFha2VlbDI5QGdtYWlsLmNvbSIsImlhdCI6MTczNzEyNDE1NiwiZXhwIjoxNzM3MzgzMzU2fQ.nW-kTjdvLCKcGY9RzdKQS4uyqXa7zGhilqccDprXQ5kqfdeN9OQjGbxhJTA_KHO58kJHc2xLShNFVa-M6eTPaEx9xsAzsdeEJgYfrIHUAVu512AxsQXI6VahsObblji274atkZCamq0A5Pb9cf1f9zEvjaaTaP4kf104awKK0sPRGTY3JmCnshqF5ABDD01KyZ1SQfX-xe_CPcNme_nYRysMcTN2xQluK9_4_VXS4F_aYY8KbsUSgDVpMI8LDo3PUy-EXX1vfW49a5RSjdz0-u27KPFMBjnzGM7I0cuNZv4Epv6DQ_onHcjEZsRor_IztqjZ3RhU22y-bS7thBmCmFxSPhHATWgKVcsyaGE_NkdacW1tWJhw1SD7hTWrGeXAVMKmsRUOPBjjXhvzap_mbUSyKWWIe3HbgGKPY5xVZb3A-5KTqhLLPyUO45LUFE-pL4tz61YMyRe4Yi4zm5QIsPOXMau78C5c4LST_IMIaME8kPKGHEQfrCxevFlL9xvx-LUO3op9F33uoFO2cb6_euxpFf-dK-9I3oB4A33nfL_mvlG-bmJh3NAMxz-uSy1Lvgiqyd--YnUQWRNRIPWLBb8gZfyN-4GGlIpLrgHiqSX3X2PQFup8usa1BkeM96dYP6IurQVTHNfvvDagFeRsIIqb1pQF9B5NWG3vX7JXbIE",
  //     },
  //     baseURL: "https://rest.staging.connectmazjid.com/v3",
  //     method: "post",
  //     url: "/admin/register-user",
  //     data:
  //       '{"name":"Mirza Test","email":"Mirzatest515@yopmail.com","role":"musaliadmin","description":"Approve!"}',
  //   },
  //   code: "ERR_BAD_REQUEST",
  //   status: 400,
  // };
  try {
    let { data } = await api.RegisterAdminWithDescription(upadatedData);
    console.log(data);
    if (data._id) {
      const snackbarDetails = {
        snackbarOpen: true,
        snackbarType: "success",
        snackbarMessage: "Added New Admin",
      };

      dispatch(ChangeSnackbar(snackbarDetails));
      return data;
    } else {
      throw new Error("Failed to add admin");
    }
    return data;
  } catch (error) {
    const errorRes = error?.response?.data;
    console.log(errorRes)
    const isDuplicate = typeof errorRes === "string" && errorRes.includes("already exists");

    const snackbarFailureDetails = {
      snackbarOpen: true,
      snackbarType: "error",
      snackbarMessage: isDuplicate?"User email already Exist": "Adding Admin Failed",
    };
    // const snackbarFailureDetails = {
    //   snackbarOpen: true,
    //   snackbarType: "error",
    //   snackbarMessage: isDuplicate
    //     ? "User email already Exist . "
    //     : errorRes
    //     ? errorRes.message
    //     : "Adding Admin Failed",
    // };

    dispatch(ChangeSnackbar(snackbarFailureDetails));
    return error;
  }
};
