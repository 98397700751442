import * as api from "../../../APICalls/index";
import { handleSnackbar } from "../../../helpers/SnackbarHelper/SnackbarHelper";

export const FetchingLatestUpdatedEvents = () => async (dispatch: any) => {
  try {
    let limit = 10;
    let page = 1;
    const response = await api.fetchLatestUpdatedEvents(limit, page);
    if (response.status === 200) {
      dispatch({
        type: "FETCH_LATEST_UPDATED_EVENTS",
        payload: response.data.data,
      });

      return response.data;
    }
    return response.data;
  } catch (error) {
    handleSnackbar(
      true,
      "error",
      `Fetching Events Failed: ${error.response.data.message}`,
      dispatch
    );
    return error.response.data;
  }
};
// export const FetchingLatestUpdatedEvents = () => async (dispatch: any) => {
//   try {
//     let limit = 10;
//     let page = 1;
//     // const response = await api.fetchLatestUpdatedEvents(limit, page);
//     const { data, error } = await client.query({
//       query: FETCH_LATEST_UPDATED_EVENTS_QUERY,
//       variables: { limit, page },
//     });
//     console.log(data, error);
//     if (error) {
//       throw new Error(error.message);
//     }
//     if (data) {
//       dispatch({
//         type: "FETCH_LATEST_UPDATED_EVENTS",
//         payload: data.getLatestUpdatedEvents,
//       });

//       return data.getLatestUpdatedEvents;
//     }
//   } catch (error) {
//     handleSnackbar(true, "error", `Fetching Events Failed`, dispatch);
//     return error;
//   }
// };
