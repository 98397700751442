// useMasjidData.js
import { useAppSelector, useAppThunkDispatch } from "../Redux/hooks";
import { FetchMasjidById } from "../Redux/Actions/MasjidActions/FetchMasjidById";
import { useEffect, useState } from "react";

const useMasjidData = (consumerMasjidId: string) => {
  const dispatch = useAppThunkDispatch();
  const [masjidData, setMasjidData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    if ((masjidData && Object.keys(masjidData).length === 0) || refetch) {
      setIsLoading(true);
      const fetchData = async () => {
        const response = await dispatch(FetchMasjidById(consumerMasjidId));
        console.log("masjid Data ", response);
        if (response?.masjidName) {
          setMasjidData(response);
        }
        setIsLoading(false);
        setRefetch(false);
      };
      fetchData();
    } else if (!masjidData && !consumerMasjidId) {
      setError("No masjid found");
    }
  }, [masjidData, consumerMasjidId, dispatch, refetch]);

  const handleRefetch = () => {
    setRefetch(true);
  };

  return { masjidData, isLoading, error, handleRefetch };
};

export default useMasjidData;
