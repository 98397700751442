import {
  Box,
  Button,
  CircularProgress,
  DialogContent,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import DetailFields from "../../../VerificationCenterComponents/Modal/DetailFields";
import DetailsButtons from "../../../VerificationCenterComponents/Modal/DetailsButtons";
import customScrollarStyles from "../../../Shared/CustomScrollBar.module.css";
import { Masjid } from "../../../../../Types";
import { Colors } from "../../../../constants/Colors/colors";
import { socialLinksHandler } from "../../../../helpers/MasjidExternalLinkHandler";
import { roleRenamer } from "../../../../helpers/RoleRenamer";
import { AdminRole, MasjidStatus } from "../Enums/enums";
import approvedBtnTick from "../../../../Photos/Newuiphotos/VerificationCenter/approvedBtnTick.webp";
import rejectedBtnCross from "../../../../Photos/Newuiphotos/VerificationCenter/rejectedBtnCross.webp";
import StatusLogs from "../../../Shared/StatusLogs/StatusLogs";
import ReasonConfirmation from "../../../Shared/ReasonConfirmation/ReasonConfirmation";
import { getFormattedMasjidStatus, getStatus } from "../Helper";

interface MasjidDetailsProps {
  selectedMasjid: Masjid;
  logs: any[];
  isLoading: boolean;
  showReasonConfirmation: boolean;
  setShowReasonConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
  showLogs: boolean;
  setShowLogs: React.Dispatch<React.SetStateAction<boolean>>;
  changingStatusTo: string;
  setChangingStatusTo: React.Dispatch<React.SetStateAction<string>>;
  handleSubmit: (status: string, reason: string) => void;
  reason: string;
  setReason: React.Dispatch<React.SetStateAction<string>>;
  reasonError: boolean;
  setReasonError: React.Dispatch<React.SetStateAction<boolean>>;
}

const MasjidDetails = ({
  selectedMasjid,
  logs,
  isLoading,
  showReasonConfirmation,
  setShowReasonConfirmation,
  showLogs,
  setShowLogs,
  changingStatusTo,
  setChangingStatusTo,
  handleSubmit,
  reason,
  setReason,
  reasonError,
  setReasonError,
}: MasjidDetailsProps) => {
  const getValueFieldStyles = (value: any) => {
    if (value) {
      return ValueFieldStyles;
    } else {
      return { ...ValueFieldStyles, color: "#FF4949" };
    }
  };
  const ValueFieldStyles = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "#1B8368",
    fontWeight: "bold",
  };

  const masjidStatus = getStatus(
    selectedMasjid?.isFreezed,
    selectedMasjid?.isVerified
  );

  const handleVerifyReject = (status: string) => {
    setChangingStatusTo(status);
    setShowReasonConfirmation(true);
  };
  const handleConfirm = (status: string) => {
    console.log(status);
    if (!reason) {
      setReasonError(true);
      return;
    }
    handleSubmit(status, reason);
  };
  return (
    <DialogContent
      className={customScrollarStyles["custom-scrollbar"]}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: isLoading ? "center" : "flex-start",
        overflowX: "hidden",
        minHeight: showLogs && logs?.length > 0 ? "auto" : "400px",
      }}
    >
      {isLoading ? (
        <CircularProgress />
      ) : showReasonConfirmation ? (
        <ReasonConfirmation
          onConfirm={handleConfirm}
          newStatus={changingStatusTo}
          reason={reason}
          setReason={setReason}
          reasonError={reasonError}
          setReasonError={setReasonError}
          isLoading={isLoading}
        />
      ) : showLogs ? (
        <StatusLogs logs={logs} />
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            marginTop: "12px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            flex: "auto",
          }}
        >
          <Box>
            <Typography variant="subtitle2">Status</Typography>
            <Typography
              variant="body2"
              sx={{
                color:
                  masjidStatus === MasjidStatus.VERIFIED ||
                  masjidStatus === null
                    ? Colors.neonGreen
                    : masjidStatus === MasjidStatus.UNVERIFIED
                    ? Colors.warningYellow
                    : Colors.rejectedRed,
                fontWeight: "bold",
                textTransform: "capitalize",
              }}
            >
              {getFormattedMasjidStatus(masjidStatus) ?? ""}
            </Typography>

            <Typography variant="subtitle2" sx={{ mt: 2 }}>
              Masjid Address
            </Typography>
            <Typography
              variant="body2"
              sx={getValueFieldStyles(selectedMasjid?.address)}
            >
              {selectedMasjid?.address ?? "Not Available"}
            </Typography>

            <Typography variant="subtitle2" sx={{ mt: 2 }}>
              Masjid Name
            </Typography>
            <Typography
              variant="body2"
              sx={getValueFieldStyles(selectedMasjid?.masjidName)}
            >
              {selectedMasjid?.masjidName ?? ""}
            </Typography>

            <Typography variant="subtitle2" sx={{ mt: 2 }}>
              Masjid Contact Number
            </Typography>
            <Typography
              variant="body2"
              sx={getValueFieldStyles(selectedMasjid?.contact)}
            >
              {selectedMasjid?.contact ?? "Not Available"}
            </Typography>

            <Typography variant="subtitle2" sx={{ mt: 2 }}>
              Masjid Website
            </Typography>
            <Typography variant="body2" sx={{ ...ValueFieldStyles }}>
              {socialLinksHandler("Website", selectedMasjid?.externalLinks) ? (
                <a
                  href={socialLinksHandler(
                    "Website",
                    selectedMasjid?.externalLinks
                  )}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textTransform: "none", color: "#1B8368" }}
                >
                  {socialLinksHandler("Website", selectedMasjid?.externalLinks)}
                </a>
              ) : (
                <Typography variant="body2" sx={getValueFieldStyles(false)}>
                  Not Available
                </Typography>
              )}
            </Typography>
            {
              // selectedMasjid?.isAssigned &&
              selectedMasjid?.assignedUser?.length > 0 && (
                <>
                  {(selectedMasjid.assignedUser[0].role ===
                    AdminRole.MUSALI_ADMIN ||
                    selectedMasjid?.assignedUser[0].role ===
                      AdminRole.SUB_ADMIN) && (
                    <>
                      <Typography variant="subtitle2" sx={{ mt: 2 }}>
                        Role
                      </Typography>

                      <Typography
                        variant="body2"
                        sx={getValueFieldStyles(
                          selectedMasjid?.assignedUser[0]?.role
                        )}
                      >
                        {roleRenamer(
                          selectedMasjid?.assignedUser[0]?.role ?? ""
                        )}
                      </Typography>
                    </>
                  )}
                  <Typography variant="subtitle2" sx={{ mt: 2 }}>
                    Full Name
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={getValueFieldStyles(
                      selectedMasjid?.assignedUser[0]?.name
                    )}
                  >
                    {selectedMasjid?.assignedUser[0]?.name ?? ""}
                  </Typography>
                  <Typography variant="subtitle2" sx={{ mt: 2 }}>
                    Email Address
                  </Typography>

                  <Typography
                    variant="body2"
                    sx={getValueFieldStyles(
                      selectedMasjid?.assignedUser[0]?.email
                    )}
                  >
                    {selectedMasjid?.assignedUser[0]?.email ?? ""}
                  </Typography>

                  <Typography variant="subtitle2" sx={{ mt: 2 }}>
                    Contact Number
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={getValueFieldStyles(
                      selectedMasjid?.assignedUser[0]?.contact
                    )}
                  >
                    {selectedMasjid?.assignedUser[0]?.contact ??
                      "Not Available"}
                  </Typography>
                </>
              )
            }
            {(masjidStatus === MasjidStatus.VERIFIED ||
              masjidStatus === MasjidStatus.REJECTED) &&
              logs.length > 0 && (
                <>
                  <Typography
                    variant="subtitle2"
                    sx={{ mt: 2, textTransform: "capitalize" }}
                  >
                    {getFormattedMasjidStatus(logs[0]?.status) ?? ""} By
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={getValueFieldStyles(logs[0]?.userDetails.name)}
                  >
                    {logs[0]?.userDetails.name ?? ""}
                  </Typography>
                  <>
                    <Typography
                      variant="subtitle2"
                      sx={{ mt: 2, textTransform: "capitalize" }}
                    >
                      {getFormattedMasjidStatus(logs[0]?.status) ?? ""} On
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={getValueFieldStyles(logs[0]?.createdAt)}
                    >
                      {logs[0]?.createdAt
                        ? moment
                            .utc(logs[0].createdAt)
                            .local()
                            .format("DD MMM, YYYY | hh:mm A")
                        : "Not Available"}
                    </Typography>
                  </>
                </>
              )}
          </Box>
          {masjidStatus === MasjidStatus.UNVERIFIED ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: 3,
                gap: 20,
                marginBottom: "20px",
              }}
            >
              <>
                <Button
                  variant="contained"
                  sx={{
                    width: "150px",
                    borderRadius: "12px",
                    bgcolor: "#00D23B",
                    "&:hover": { bgcolor: "#00D23B" },
                    textTransform: "none",
                  }}
                  onClick={() => {
                    handleVerifyReject("approved");
                  }}
                >
                  <Box
                    component="img"
                    src={approvedBtnTick}
                    width={"14px"}
                    marginRight="5px"
                  />
                  Verify
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    width: "150px",
                    borderRadius: "12px",
                    bgcolor: "#F93737",
                    "&:hover": { bgcolor: "#F93737" },
                    textTransform: "none",
                  }}
                  onClick={() => {
                    handleVerifyReject("rejected");
                  }}
                >
                  <Box
                    component="img"
                    src={rejectedBtnCross}
                    width={"11px"}
                    marginRight="5px"
                  />
                  Reject
                </Button>
              </>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "12px",
                mt: 3,
                gap: 2,
              }}
            >
              <>
                <Button
                  variant="text"
                  sx={{
                    color: "#3D5347",
                    textDecoration: "underline",
                    textTransform: "none",
                    fontSize: "13.5px",
                    width: "150px",
                    fontWeight: "600",
                    "&:hover": { textDecoration: "underline" },
                  }}
                  onClick={() => {
                    setShowLogs(true);
                  }}
                >
                  Check Logs
                </Button>
              </>
            </Box>
          )}
        </Box>
      )}
    </DialogContent>
  );
};

export default MasjidDetails;
