import { Box, Button, CircularProgress, TextField } from "@mui/material";
import React from "react";
import approvedBtnTick from "../../../Photos/Newuiphotos/VerificationCenter/approvedBtnTick.webp";
import rejectedBtnCross from "../../../Photos/Newuiphotos/VerificationCenter/rejectedBtnCross.webp";
import { getAction } from "../../PageComponents/MasjidTable/Helper";
interface ReasonConfirmationprops {
  onConfirm: (status: string) => void;
  reason: string;
  setReason: React.Dispatch<React.SetStateAction<string>>;
  reasonError: boolean;
  setReasonError: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  status: string;
}
const ReasonConfirmation = ({
  onConfirm,
  reason,
  setReason,
  reasonError,
  setReasonError,
  isLoading,
  status = "pending",
}: ReasonConfirmationprops) => {
  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Box sx={{ height: "100%" }} mt={2}>
        <TextField
          fullWidth
          label={`Reason`}
          sx={{
            border: reasonError ? "1px solid red" : "",
          }}
          value={reason}
          onChange={(e) => {
            setReasonError(false);
            setReason(e.target.value);
          }}
          multiline
          rows={12}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: 3,
          gap: 20,
        }}
      >
        {(status === "pending" || status === "freezing") && (
          <Button
            variant="contained"
            sx={{
              whiteSpace: "nowrap",
              padding: "10px 20px",
              width: "auto",
              borderRadius: "12px",
              bgcolor: "#F93737",
              "&:hover": {
                bgcolor: "#F93737",
              },
              "&.Mui-disabled": { bgcolor: "#B0B0B0", color: "white" },
              fontSize: "12px",
              textTransform: "none",
            }}
            onClick={() => {
              !isLoading ? onConfirm("rejected") : null;
            }}
          >
            <Box
              component="img"
              src={approvedBtnTick}
              width={"11px"}
              marginRight="5px"
            />
            Confirm Reject
            {isLoading && (
              <CircularProgress sx={{ marginLeft: "10px" }} size={"20px"} />
            )}
          </Button>
        )}
        {(status === "pending" || status === "approving") && (
          <Button
            variant="contained"
            sx={{
              whiteSpace: "nowrap",
              padding: "10px 20px",
              width: "auto",
              borderRadius: "12px",

              bgcolor: "#00D23B",
              "&:hover": {
                bgcolor: "#00D23B",
              },
              "&.Mui-disabled": { bgcolor: "#B0B0B0", color: "white" },
              fontSize: "12px",
              textTransform: "none",
            }}
            onClick={() => {
              !isLoading ? onConfirm("approved") : null;
            }}
          >
            <Box
              component="img"
              src={approvedBtnTick}
              width={"14px"}
              marginRight="5px"
            />
            Confirm Assign
            {isLoading && (
              <CircularProgress sx={{ marginLeft: "10px" }} size={"20px"} />
            )}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default ReasonConfirmation;
