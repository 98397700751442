import React from 'react'
import PageContainer from '../../../Shared/PageContainer/PageContainer'
import InternalAdminsTable from '../../../../Components/AdminComponents/InternalAdmins/InternalAdminsTable'

const InternalAdmins = () => {
  return (
    <>
    <PageContainer pt={"50px"} title="Internal Admins">
      <div className="RightFeedContainer">
        <InternalAdminsTable />
      </div>
    </PageContainer>
  </>
  )
}

export default InternalAdmins
