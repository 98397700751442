import { gql } from "@apollo/client";

export const CREATE_MEMBER = gql`
  mutation CreateMember($input: CreateMemberInput!) {
    createMember(input: $input) {
      personName
      email
      phoneNumber
      designation
      linkedIn
      img
    }
  }
`;
export const CREATE_WIDGET = gql`
  mutation AuthenticateWidget($input: WidgetAuthPayload!) {
    widgetAuth(input: $input)
  }
`;

export const UPDATE_MEMBER = gql`
  mutation UpdateMember($input: UpdateMemberInput!) {
    updateMember(input: $input) {
      _id
      personName
      email
      phoneNumber
      designation
      linkedIn
      img
    }
  }
`;
export const DELETE_MEMBER = gql`
  mutation DeleteMember($_id: ID!) {
    deleteMember(_id: $_id)
  }
`;
export const CREATE_MESSAGE = gql`
  mutation CreateMessage($message: MessageInput!) {
    createMessage(message: $message) {
      masjidId
      body
    }
  }
`;

export const FREEZE_MASJID = gql`
  mutation FreezeMasjid($id: String!, $isFreezed: Boolean!) {
    freeze(id: $id, isFreezed: $isFreezed)
  }
`;

export const CREATE_MASJID = gql`
  mutation CreateMasjid($input: MasjidInput!) {
    createMasjid(input: $input) {
      masjidName
      description
      address
      _id
      location {
        type
        coordinates
      }
      contact
      externalLinks {
        name
        url
      }
    }
  }
`;
